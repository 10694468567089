import { useContext, useMemo } from 'react';
import { formatCurrency } from '../../utils/formatters';
import InternalAssignmentsContext from '../context/InternalAssignmentsContext';

const COMMISSION_CURRENCY = 'usd';

const useCalculateCommissionByUser = ({
  selectedCRX, selectedIS, rows, byUser,
}) => {
  const { crxUsers, isUsers } = useContext(InternalAssignmentsContext);
  const allCrxUserList = useMemo(() => crxUsers.map(({ userId: id, userName: name }) => ({ id, name })), [crxUsers]);
  const allIsUserList = useMemo(
    () => isUsers.map(({ userId: value, userName: label }) => ({ id: value, name: label })),
    [isUsers],
  );

  const crxUserList = allCrxUserList.filter((opt) => selectedCRX.includes(opt.id));
  const isUserList = allIsUserList.filter((opt) => selectedIS.includes(opt.id));

  const data = useMemo(() => {
    if (!byUser) {
      return rows;
    }

    const result = {};

    let userList = [];
    if (selectedCRX.length > 0) {
      userList = crxUserList;
    } else if (selectedIS.length > 0) {
      userList = isUserList;
    } else {
      userList = [];
    }

    userList.forEach(({ id, name }) => {
      let totalCommission = 0;

      rows.forEach((ele) => {
        const item = ele.data;
        if (selectedCRX.length > 0 && item.assignments.CRX.includes(id)) {
          totalCommission += item.commissions.crxCommission;
        } else if (selectedIS.length > 0 && item.sellerId === id) {
          totalCommission += item.commissions.crxCommission;
        }
      });

      if (totalCommission > 0) {
        result[id] = { name, totalCommission };
      }
    });

    const resultArray = Object.entries(result).map(([id, { name, totalCommission }]) => ({
      id,
      name,
      totalCommission: formatCurrency(totalCommission, COMMISSION_CURRENCY, { minimumFractionDigits: 2 }),
    }));

    return resultArray;
  }, [byUser, crxUserList, isUserList, rows, selectedCRX, selectedIS]);

  return data;
};

export default useCalculateCommissionByUser;
