const texts = {
  headers: {
    onboarding: {
      text: 'Payment Link',
      tooltip: 'Get the payment link',
    },
    coach: {
      text: 'Coach',
    },
    name: {
      text: 'Name',
    },
    email: {
      text: 'Email',
    },
    phoneNumber: {
      text: 'Phone Number',
    },
    gender: {
      text: 'Gender',
    },
    submitDate: {
      text: 'Submit Date',
    },
    country: {
      text: 'Country',
    },
    state: {
      text: 'Status',
    },
    action: {
      text: 'Info',
      tooltip: 'Info',
    },
    delete: {
      text: 'Delete',
      tooltip: 'Remove Lead',
    },
    salesStage: {
      text: 'Status',
    },
    lastContact: {
      text: 'Last Contact',
    },
  },
  defaultRowValues: {
    country: 'Unknown',
    name: 'Unknown',
    empty: '-',
  },
  leadDeleteMessage: 'Lead {leadName} was successfully deleted! ',
  deleteConfirm: 'Are you sure you want to delete this lead?',
  multipleCoachesFinalStatusMessage: 'Please select only one coach to see the leads in final status',
  noRows: 'No rows',
};

export default texts;
