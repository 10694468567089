import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import {
  Container,
  Description,
  StyledAutoComplete,
} from './styles';

// Function to sort option labels in ascending order
const SortByLabel = (a, b) => (a.label.localeCompare(b.label));

const AutoComplete = ({
  className,
  initialValue,
  description,
  onChange,
  options,
  ...otherProps
}) => {
  const [selectedOption, setSelectedOption] = useState(options.find((option) => option.id === initialValue) || null);

  const handleOnChange = (value) => {
    setSelectedOption(value);
    onChange(value);
  };

  const renderOption = (props, option) => (
    <div
      {...props}
      key={option.id}
    >
      {option.label}
    </div>
  );

  return (
    <Container>
      {!!description && <Description>{description}</Description>}
      <StyledAutoComplete
        className={className}
        options={options.sort(SortByLabel)}
        value={selectedOption}
        onChange={(_, value) => handleOnChange(value)}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        renderOption={renderOption}
        {...otherProps}
      />
    </Container>
  );
};

AutoComplete.propTypes = {
  className: PropTypes.string,
  initialValue: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

AutoComplete.defaultProps = {
  className: '',
  initialValue: null,
  description: '',
  options: [],
  onChange: () => {},
};

export default AutoComplete;
