import Collections from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

class CheckinFeedback extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.CHECK_IN_FEEDBACK}/${id}`, opts);
  }

  get aiFeedbackText() {
    return this.data.aiFeedbackText || '';
  }

  get feedbackText() {
    return this.data.feedbackText || '';
  }

  get user() {
    return this.data.user;
  }

  get coach() {
    return this.data.coach;
  }

  get createdAt() {
    return this.data.createdAt?.toDate();
  }

  get updatedAt() {
    return this.data.updatedAt?.toDate();
  }

  get checkInSubmittedAt() {
    return this.data.checkInSubmittedAt?.toDate();
  }

  get sentAt() {
    return this.data.sentAt?.toDate();
  }

  get sentFeedbackText() {
    return this.data.aiFeedbackText || '';
  }

  get messages() {
    return this.data.messages || [];
  }

  get entriesAt() {
    return this.data.entriesAt || [];
  }

  get regeneratedCount() {
    return this.data.regeneratedCount || 0;
  }

  get totalTimeSpent() {
    return this.data.totalTimeSpent || 0;
  }

  get customFeedbackText() {
    return this.data.customFeedbackText || '';
  }

  get attachments() {
    return this.data.attachments || [];
  }

  static async getById(id) {
    const checkInFeedbackDoc = new CheckinFeedback(id);
    await checkInFeedbackDoc.init();
    return checkInFeedbackDoc.exists ? checkInFeedbackDoc : null;
  }
}

export default CheckinFeedback;
