import styled from 'styled-components';
import {
  TextField,
} from '@mui/material';

import Select from '../../../../../components/v2/Select';
import { fontFamily } from '../../../../../styles/text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 60px;
`;

const Title = styled.div`
  font-family: ${fontFamily.sansSerif};
  flex: 1;
  font-size: 24px;
  white-space: nowrap;
  text-align: center;
  margin: 20px;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
  width: 100%;
`;

const StyledSelectField = styled(Select)`
  margin-bottom: 20px;
  width: 100%;
  height: 55px;
`;

export {
  Title,
  Container,
  StyledTextField,
  StyledSelectField,
};
