import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';
import { InfoOutlined } from '@mui/icons-material';
import { compose } from 'recompose';
import { observer } from 'mobx-react';

import {
  LeadHistoryType,
  LeadState,
} from '../../../../../../Model/Lead';
import { DateFormat } from '../../../../../../../utils/date';
import statesTexts from '../../../../states.json';
import salesStageTexts from '../../../../salesStages.json';
import MsgModal from '../../../MsgModal';
import MsgEndpointContext, {
  withMsgEndpointContextProvider,
} from '../../../../../../context/MsgEndpointContext';
import { formatText } from '../../../../../../utils/textUtils';
import texts from './texts.json';
import {
  Container,
  RowContainer,
  NoteCredit,
  Separator,
  StyledTypography,
  ColumnContainer,
  StrongTypography,
  StyledInfoButton,
} from './styles';

const Note = ({
  type,
  value,
  reason,
  date,
  name,
  createdUserId,
  coachId,
}) => {
  const [showMsgModal, setShowMsgModal] = useState(false);
  const momentDate = useMemo(() => moment(date.toDate()), [date]);
  const dateDifference = useMemo(() => momentDate.fromNow(), [momentDate]);
  const { msgEndpointCollection } = useContext(MsgEndpointContext);

  const historyText = useMemo(() => {
    let provider = '';
    let message = '';
    switch (type) {
      case LeadHistoryType.STATE_CHANGE:
        return value === LeadState.NEW ? texts.newLeadCreated
          : format(texts[type], { value: statesTexts[value] || value });
      case LeadHistoryType.SALES_STAGE_CHANGE:
        return format(texts[type], { value: salesStageTexts[value] || value, reason });
      case LeadHistoryType.MSG:
        if (
          [
            salesStageTexts.FIRST_MESSAGE,
            salesStageTexts.SECOND_MESSAGE,
            salesStageTexts.THIRD_MESSAGE,
          ].includes(value.msgTemplate.name)
        ) {
          message = value.msgTemplate.name;
        } else {
          message = texts.message;
        }
        return formatText(format(texts[type], { message }));
      case LeadHistoryType.CALL:
        return format(texts[type], { duration: value.callDuration });
      case LeadHistoryType.CALL_PLACED:
        return format(texts[type], {
          isName: name,
          date: moment(value.callTime.toDate()).format(DateFormat.DATE_MONTH_YEAR_TIME_SHORT_DISPLAY_FORMAT),
        });
      case LeadHistoryType.CALENDLY_SCHEDULED:
        return format(texts[type], {
          isName: value.isUserName,
          duration: moment(value.endTime.toDate()).diff(moment(value.startTime.toDate()), 'minutes'),
          startTime: moment(value.startTime.toDate()).format(DateFormat.DATE_MONTH_TIME_SHORT_DISPLAY_FORMAT),
        });
      case LeadHistoryType.RESCHEDULED_MANUALLY:
        return format(texts[type], {
          isName: value.isUserName,
          callTime: moment(value.callTime.toDate()).format(DateFormat.DATE_MONTH_TIME_SHORT_DISPLAY_FORMAT),
        });
      case LeadHistoryType.DIRECT_PURCHASE_LINK_SENT:
        if (value.smsSent && value.emailSent) {
          provider = texts.emailAndSms;
        } else if (value.smsSent) {
          provider = texts.sms;
        } else {
          provider = texts.email;
        }
        return format(texts[type], { provider });
      case LeadHistoryType.SENT_TO_RESCHEDULE:
        return format(texts[type], { reason });
      case LeadHistoryType.MSG_SENT:
        return format(texts[type]);
      case LeadHistoryType.RESPONSE:
        return format(texts[type]);
      case LeadHistoryType.NOTE:
        return coachId === createdUserId
          ? format(texts.coachNote, { note: value })
          : value;
      default:
        return value;
    }
  }, [
    type,
    value,
    reason,
    name,
    coachId,
    createdUserId,
  ]);

  return (
    <Container>
      {showMsgModal && (
        <MsgModal
          open={showMsgModal}
          onClose={() => setShowMsgModal(false)}
          contactNo={value.contactNo}
          provider={value.msgProvider}
          msgTemplate={{
            ...value.msgTemplate,
            msgBody: value.messageBody,
          }}
          readOnly
          sendAsUserId={value.sendAs}
          msgEndpoints={msgEndpointCollection?.docs || []}
        />
      )}
      <RowContainer>
        <StyledTypography isDate>
          {momentDate.format(DateFormat.DATE_MONTH_TIME_SHORT_DISPLAY_FORMAT)}
        </StyledTypography>
        <Separator>
          {texts.separator}
        </Separator>
        <ColumnContainer>
          <StyledTypography>
            {historyText}
            {type === LeadHistoryType.MSG && (
              <StyledInfoButton size="small" onClick={() => setShowMsgModal(true)}>
                <InfoOutlined fontSize="small" />
              </StyledInfoButton>
            )}
          </StyledTypography>
          <NoteCredit>
            <StrongTypography>
              {dateDifference}
            </StrongTypography>
            {formatText(format(texts.userName, {
              name,
            }))}
          </NoteCredit>
        </ColumnContainer>
      </RowContainer>
    </Container>
  );
};

Note.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  date: PropTypes.objectOf(Date).isRequired,
  name: PropTypes.string.isRequired,
  reason: PropTypes.string,
  createdUserId: PropTypes.string.isRequired,
  coachId: PropTypes.string,
};

Note.defaultProps = {
  reason: '',
  coachId: '',
};

export default compose(
  withMsgEndpointContextProvider,
  observer,
)(Note);
