import React, {
  useCallback,
  useContext,
  useState,
} from 'react';
import format from 'string-template';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import useComponentMounted from '../../../../../hooks/useComponentMounted';
import useLogger from '../../../../../hooks/useLogger';
import useSessionStore from '../../../../../hooks/useSessionStore';
import useCurrentLoggedInUser from '../../../../../hooks/useCurrentLoggedInUser';
import useStorage from '../../../../../hooks/useStorage';
import { CoachingActivity } from '../../../../../utils/log';
import UserContext, {
  withUserContextReady,
} from '../../../../../context/UserContext';
import useToast from '../../../../hooks/useToast';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import InputLabel from '../../../../../components/v2/InputLabel';
import {
  CardsContainer,
  Card,
  HeaderContainer,
  TitleContainer,
  Title,
  SubTitle,
  Container,
} from '../../../../../components/v2/Card';
import { SaveButton } from '../../../../../components/Button/ActionButtons';
import ImageConfigurator from '../../../../../components/ImageConfigurator';
import {
  StyledTextFieldContainer,
} from '../../../../../components/Inputs';

import { uploadImage } from './utils';
import texts from './texts.json';

const ProfileSettings = () => {
  const { userDoc } = useContext(UserContext);

  const { isAdmin } = useSessionStore();
  const { isCurrentLoggedInUserInPath } = useCurrentLoggedInUser();
  const { uploadAttachment } = useStorage();

  const { avatarUrl = '' } = userDoc || {};

  const [avatar, setAvatar] = useState(avatarUrl);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { showToast } = useToast();
  const { logCoachingActivity } = useLogger();
  const isComponentMountedRef = useComponentMounted();

  const handleSave = useCallback(async () => {
    try {
      // Upload the avatar to firebase storage
      setIsLoading(true);
      const publicUrl = await uploadImage(
        avatar,
        uploadAttachment,
        imageToUpload,
        userDoc.id,
      );
      // Update the profile image if the upload was successful
      if (publicUrl) {
        await userDoc.updateFields({
          avatarUrl: publicUrl,
        });
      }

      if (isComponentMountedRef.current) {
        setImageToUpload(null);
      }

      logCoachingActivity(CoachingActivity.UPDATED_COACH_SETTINGS);
      showToast(texts.saved);
    } catch (err) {
      showToast(format(texts.error, { err }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [
    isComponentMountedRef,
    logCoachingActivity,
    uploadAttachment,
    showToast,
    userDoc,
    imageToUpload,
    avatar,
  ]);

  const isReadOnly = !(isAdmin || isCurrentLoggedInUserInPath);

  return (
    <CardsContainer>
      <Card>
        <HeaderContainer>
          <TitleContainer>
            <Title>{texts.title}</Title>
            <SubTitle>{texts.description}</SubTitle>
          </TitleContainer>
        </HeaderContainer>
        <Container>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.avatar}</InputLabel>
            <ImageConfigurator
              imageUrl={avatar}
              setImagePreview={setAvatar}
              fileToUpload={imageToUpload}
              setFileToUpload={setImageToUpload}
              isReadOnly={isReadOnly}
            />
          </StyledTextFieldContainer>
          <SaveButton
            onClick={handleSave}
            disabled={!imageToUpload}
            $fitToContent
          >
            {texts.saveButton}
          </SaveButton>
        </Container>
        <LoadingOverlay isLoading={isLoading} />
      </Card>
    </CardsContainer>
  );
};

export default compose(
  withUserContextReady,
  observer,
)(ProfileSettings);
