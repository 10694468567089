import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { autorun } from 'mobx';
import { useRouteMatch } from 'react-router-dom';
import {
  Checkbox,
  Paper,
  Box,
} from '@mui/material';

import CoachesListContext from '../../../../context/CoachesListContext';
import ExternalCoachContext from '../../../../context/ExternalCoachContext';

import {
  CoachSelectWrapper,
  StyledAutoComplete,
  StyledOption,
  StyledTextField,
  CheckboxContainer,
} from './styles';
import texts from './texts.json';

/*
  Let's define a function that will be used to sort the coaches by name. This can be moved to a utils file if used
  somewhere else. We use the label property found in Select options arrays to perform the sorting.
*/
const SortByLabel = (a, b) => a.label.localeCompare(b.label);

const CoachSelect = ({
  role,
  selectedUserId,
  onChange,
  loadNewCoachDataOnChange,
  enableMultipleSelection,
  hideLabel,
  preselectedCoach,
}) => {
  const [selectedCoaches, setSelectedCoaches] = useState(enableMultipleSelection ? [] : null);
  const [coachOptions, setCoachOptions] = useState([]);

  const {
    loadNewCoachData = () => {},
  } = useContext(ExternalCoachContext);
  const {
    coachesCollection,
    coachesByRole = {},
  } = useContext(CoachesListContext);

  useEffect(() => () => {
    if (preselectedCoach) {
      setSelectedCoaches(enableMultipleSelection ? [preselectedCoach] : preselectedCoach);
      loadNewCoachData(preselectedCoach.id);
    } else {
      loadNewCoachData();
    }
  }, [
    enableMultipleSelection,
    loadNewCoachData,
    preselectedCoach,
  ]);

  const {
    params: {
      userId: currentUserId,
    },
  } = useRouteMatch();

  const userId = selectedUserId || currentUserId;

  useEffect(() => {
    const disposer = autorun(() => {
      let options = [];
      if (role && coachesByRole[role]) {
        const assignedCoaches = [];
        const otherCoaches = [];

        // If the role is specified, then we want to show the coaches with that role first.
        coachesByRole[role].forEach(({ assignee, coachDoc }) => {
          const { id, name } = coachDoc;
          if (assignee === userId) {
            assignedCoaches.push({ id, label: name });
          } else {
            otherCoaches.push({ id, label: name });
          }
        });

        // Sort both arrays alphabetically before building the final options array.
        assignedCoaches.sort(SortByLabel);
        otherCoaches.sort(SortByLabel);

        // Mark the last coach of the assigned coaches as a divider.
        if (assignedCoaches.length > 0) {
          assignedCoaches[assignedCoaches.length - 1].isDivider = true;
        }

        options = [
          ...assignedCoaches,
          ...otherCoaches,
        ];
      } else {
        options = coachesCollection.docs.map(({ id, name }) => ({ id, label: name }));
        // Order the coaches alphabetically so that we can render them correctly.
        options.sort(SortByLabel);
      }

      setCoachOptions(options);
    });

    return disposer;
  }, [
    userId,
    role,
    coachesCollection,
    coachesByRole,
  ]);

  useEffect(() => {
    if (coachOptions.length) {
      /*
       * Sync selected coaches whenever the autorun is triggered (meaning a coach was updated).
       * This is necessary if a coach's name is updated and that coach is selected).
       */
      setSelectedCoaches((prev) => {
        if (enableMultipleSelection) {
          return prev.reduce((acc, selected) => {
            const updatedCoach = coachOptions.find((option) => option.id === selected.id);
            if (updatedCoach) {
              acc.push(updatedCoach);
            }
            return acc;
          }, []);
        }
        if (prev) {
          const updatedCoach = coachOptions.find((option) => option.id === prev.id);
          return updatedCoach || null;
        }
        return prev;
      });
    }
  }, [
    coachOptions,
    enableMultipleSelection,
  ]);

  const onCoachSelected = useCallback((event, selectedOption) => {
    const selectedOptionValue = enableMultipleSelection ? [...selectedOption] : selectedOption;

    // Update the select value.
    setSelectedCoaches(selectedOptionValue);

    if (loadNewCoachDataOnChange) {
      // Get the ids we need to load data for.
      if (enableMultipleSelection) {
        // If multiple selection is enabled, then we need to get the ids from the selected options array.
        const ids = selectedOptionValue.map(({ id }) => id);
        loadNewCoachData(ids);
      } else {
        // If a single coach has been selected, then we need to notify the context, so that the new data is loaded.
        loadNewCoachData(selectedOption ? [selectedOption.id] : null);
      }
    }

    // Notify our parent component, which might need to do something with this data.
    onChange(selectedOption);
  }, [
    loadNewCoachData,
    onChange,
    loadNewCoachDataOnChange,
    enableMultipleSelection,
  ]);

  const renderOption = (props, option) => {
    // Render checkboxes for each option if multiple selection is enabled.
    if (enableMultipleSelection) {
      const isChecked = selectedCoaches.some(({ id }) => id === option.id);

      return (
        <StyledOption {...props} key={option.id} $isDivider={!!option.isDivider}>
          <Checkbox
            /*
              If we control the selected coaches list, then we can always mark the correct items in the list, no matter
              which IS agent is selected.
            */
            checked={isChecked}
          />
          {option.label}
        </StyledOption>
      );
    }

    return (
      <StyledOption
        {...props}
        $isDivider={!!option.isDivider}
        key={option.id}
      >
        {option.label}
      </StyledOption>
    );
  };

  const focused = enableMultipleSelection && !selectedCoaches.length;

  const placeholder = focused ? texts.placeholder : '';

  const onSelectAllChange = (event) => {
    if (event.target.checked) {
      onCoachSelected({}, coachOptions);
    } else {
      onCoachSelected({}, []);
    }
  };

  const renderSelectorPaper = ({ children, ...rest }) => (
    <Paper {...rest}>
      <Box>
        <StyledOption
          $isDivider
          /*
            We don't want the label to activate the checkbox. If we need to select all coaches, we need to
            click on the box itself. This way, we reduce the chances of clicking on this option accidentally.
          */
          onMouseDown={(e) => e.preventDefault()}
        >
          <CheckboxContainer>
            <Checkbox
              checked={selectedCoaches.length === coachOptions.length}
              onClick={onSelectAllChange}
            />
            {texts.placeholder}
          </CheckboxContainer>
        </StyledOption>
        {children}
      </Box>
    </Paper>
  );

  return (
    <CoachSelectWrapper>
      <StyledAutoComplete
        disablePortal
        id="coach-select"
        options={coachOptions}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            label={hideLabel ? '' : texts.label}
            placeholder={placeholder}
            // We handle the focused prop manually only when multiple selection is enabled
            {...(enableMultipleSelection ? { focused } : {})}
          />
        )}
        renderOption={renderOption}
        value={selectedCoaches}
        onChange={onCoachSelected}
        multiple={enableMultipleSelection}
        limitTags={1}
        disableCloseOnSelect={enableMultipleSelection}
        PaperComponent={enableMultipleSelection ? renderSelectorPaper : undefined}
      />
    </CoachSelectWrapper>
  );
};

CoachSelect.propTypes = {
  role: PropTypes.string,
  selectedUserId: PropTypes.string,
  onChange: PropTypes.func,
  loadNewCoachDataOnChange: PropTypes.bool,
  enableMultipleSelection: PropTypes.bool,
  hideLabel: PropTypes.bool,
  preselectedCoach: PropTypes.object,
};

CoachSelect.defaultProps = {
  role: '',
  selectedUserId: '',
  onChange: () => { },
  loadNewCoachDataOnChange: true,
  enableMultipleSelection: false,
  hideLabel: false,
  preselectedCoach: null,
};

export default compose(
  observer,
)(CoachSelect);
