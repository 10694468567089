import { Collection } from 'firestorter';

import CollectionName from '../../utils/collections';
import BaseDocument from '../../Model/BaseDocument';

/**
 * The type of user activities
 */
const UserActivityType = {
  LEAD_STATUS_CHANGE: 'LEAD_STATUS_CHANGE',
  LEAD_CALL_PLACED: 'LEAD_CALL_PLACED',
  LEAD_MSG: 'LEAD_MSG',
  SUBSCRIPTION_STATUS_CHANGE: 'SUBSCRIPTION_STATUS_CHANGE',
  WORKOUT_COMPLETED: 'WORKOUT_COMPLETED',
  CHECK_IN_SUBMITTED: 'CHECK_IN_SUBMITTED',
  HABIT_SUBMITTED: 'HABIT_SUBMITTED',
  PAYMENT_SUCCESSFUL: 'PAYMENT_SUCCESSFUL',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PAYMENT_REFUNDED: 'PAYMENT_REFUNDED',
  SIGN_UP: 'SIGN_UP',
  POST_PAYMENT_FORM_SUBMIT: 'POST_PAYMENT_FORM_SUBMIT',
};

/**
 * Class representing a user activity that holds the user activity data,
 * which is used to track the client activity history such as workout completion, check-in submission, etc.
 *
 * @class UserActivity
 * @extends BaseDocument
 */
class UserActivity extends BaseDocument {
  constructor(id, opts) {
    super(`${CollectionName.USER_ACTIVITY}/${id}`, opts);
  }

  /**
   * Get the type of activity
   * @return {string}
   */
  get type() {
    return this.data.type;
  }

  /**
   * Get the activity created date in UTC format
   * @return {Date}
   */
  get createdAt() {
    return this.data.createdAt.toDate();
  }

  /**
   * Get the activity data
   * @return {object|string}
   */
  get activityData() {
    return this.data.data;
  }

  /**
   * Get the user activities for a given user
   *
   * @param {string} userId - The user id
   * @returns {Observable<Collection<UserActivity>>}
   */
  static async getActivitiesByUser(userId) {
    const collection = new Collection(CollectionName.USER_ACTIVITY, {
      createDocument: ({ id }, opts) => new UserActivity(id, opts),
      query: (ref) => ref
        .where('userId', '==', userId)
        .orderBy('createdAt', 'desc'),
    });
    await collection.fetch();
    return collection;
  }
}

export default UserActivity;
export {
  UserActivityType,
};
