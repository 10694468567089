import React from 'react';
import PropTypes from 'prop-types';

import Table from '../Table';

const CommissionFullDataTable = ({
  rows, columns, isLoading,
}) => (
  <Table
    rows={rows}
    columns={columns}
    columnVisibilityModel={{
      coach: false,
      connectedAccountDirectChargeId: false,
      insideSalesCommission: false,
      crxCommission: false,
      feesConfigurationObject: false,
    }}
    isLoading={isLoading}
  />
);

CommissionFullDataTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

CommissionFullDataTable.defaultProps = {
  isLoading: true,
};

export default CommissionFullDataTable;
