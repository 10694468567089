import React from 'react';
import MessageTemplates from '../MessageTemplates';
import MobileApp from './MobileApp';
import SlackNotifications from './SlackNotifications';
import CoachSettings from './CoachSettings';
import texts from './texts.json';
import {
  MobileAppIcon,
  SlackNotificationsIcon,
  CoachSettingsIcon,
  MessageTempIcon,
} from './styles';

const tabs = {
  MOBILE_APP: 'MOBILE_APP',
  SLACK_NOTIFICATIONS: 'SLACK_NOTIFICATIONS',
  COACH_SETTINGS: 'COACH_SETTINGS',
  MESSAGE_TEMPLATES: 'MESSAGE_TEMPLATES',
};

const tabsConfig = {
  [tabs.MOBILE_APP]: {
    label: texts.tabLabels[tabs.MOBILE_APP],
    icon: MobileAppIcon,
    component: MobileApp,
  },
  [tabs.SLACK_NOTIFICATIONS]: {
    label: texts.tabLabels[tabs.SLACK_NOTIFICATIONS],
    icon: SlackNotificationsIcon,
    component: SlackNotifications,
  },
  [tabs.COACH_SETTINGS]: {
    label: texts.tabLabels[tabs.COACH_SETTINGS],
    icon: CoachSettingsIcon,
    component: CoachSettings,
  },
  [tabs.MESSAGE_TEMPLATES]: {
    label: texts.tabLabels[tabs.MESSAGE_TEMPLATES],
    icon: MessageTempIcon,
    component: (props) => <MessageTemplates {...props} isCRX />,
  },
};

export {
  tabs,
  tabsConfig,
};
