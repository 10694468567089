import styled from 'styled-components';
import {
  Typography,
} from '@mui/material';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TemplatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-top: 50px;
`;

const MessageTemplateSelectWrapper = styled.div`
  width: 300px;
`;

const NoTemplateSelectedText = styled(Typography)` 
  margin: 20px;
`;

export {
  Container,
  Title,
  TemplatesContainer,
  MessageTemplateSelectWrapper,
  NoTemplateSelectedText,
};
