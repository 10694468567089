import { useContext } from 'react';

import {
  ConfigurationType,
  configurationPagesConfig,
} from '../../../../config/configurationPages';
import ConfigurationMenuContext from '../../../../context/ConfigurationMenuContext';

const useConfigurationListConfig = () => {
  const { navigateToConfig } = useContext(ConfigurationMenuContext);

  const configurationListEntries = [
    {
      configurationId: ConfigurationType.PAYMENT,
      onClick: () => navigateToConfig(ConfigurationType.PAYMENT),
      icon: configurationPagesConfig[ConfigurationType.PAYMENT].icon,
      title: configurationPagesConfig[ConfigurationType.PAYMENT].title,
    },
    {
      configurationId: ConfigurationType.CHECKOUT_PAGE,
      onClick: () => navigateToConfig(ConfigurationType.CHECKOUT_PAGE),
      icon: configurationPagesConfig[ConfigurationType.CHECKOUT_PAGE].icon,
      title: configurationPagesConfig[ConfigurationType.CHECKOUT_PAGE].title,
    },
    {
      configurationId: ConfigurationType.WELCOME_MESSAGE,
      onClick: () => navigateToConfig(ConfigurationType.WELCOME_MESSAGE),
      icon: configurationPagesConfig[ConfigurationType.WELCOME_MESSAGE].icon,
      title: configurationPagesConfig[ConfigurationType.WELCOME_MESSAGE].title,
    },
    {
      configurationId: ConfigurationType.HABITS,
      onClick: () => navigateToConfig(ConfigurationType.HABITS),
      icon: configurationPagesConfig[ConfigurationType.HABITS].icon,
      title: configurationPagesConfig[ConfigurationType.HABITS].title,
    },
    {
      configurationId: ConfigurationType.CHECK_IN,
      onClick: () => navigateToConfig(ConfigurationType.CHECK_IN),
      icon: configurationPagesConfig[ConfigurationType.CHECK_IN].icon,
      title: configurationPagesConfig[ConfigurationType.CHECK_IN].title,
    },
    {
      configurationId: ConfigurationType.PRODUCTS,
      onClick: () => navigateToConfig(ConfigurationType.PRODUCTS),
      icon: configurationPagesConfig[ConfigurationType.PRODUCTS].icon,
      title: configurationPagesConfig[ConfigurationType.PRODUCTS].title,
    },
    {
      configurationId: ConfigurationType.PROFILE_SETTINGS,
      onClick: () => navigateToConfig(ConfigurationType.PROFILE_SETTINGS),
      icon: configurationPagesConfig[ConfigurationType.PROFILE_SETTINGS].icon,
      title: configurationPagesConfig[ConfigurationType.PROFILE_SETTINGS].title,
    },
    {
      configurationId: ConfigurationType.OTHER_SETTINGS,
      onClick: () => navigateToConfig(ConfigurationType.OTHER_SETTINGS),
      icon: configurationPagesConfig[ConfigurationType.OTHER_SETTINGS].icon,
      title: configurationPagesConfig[ConfigurationType.OTHER_SETTINGS].title,
    },
  ];

  return {
    configurationListEntries,
  };
};

export default useConfigurationListConfig;
