import { Collection } from 'firestorter';
import moment from 'moment';
import uuid from 'uuid/v4';

import { firestorePaths } from '../utils/paths';
import { sanitizeForID } from '../utils/string';
import { DateFormat } from '../utils/date';
import BaseDocument from './BaseDocument';
import Workout from './Workout';
import WorkoutDefinition from './WorkoutDefinition';

const workoutAssignmentStatuses = {
  COMPLETED: 'COMPLETED',
  ASSIGNED: 'ASSIGNED',
  PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
};

class WorkoutAssignment extends BaseDocument {
  async init() {
    if (this.data.workoutContent) {
      this.workoutDefinition = new WorkoutDefinition(this.data.workoutContent);
    } else {
      /*
        Fallback to workoutDefinition from Workout entity for
        backward compatibility
      */
      const workoutDoc = new Workout(() => `workout/${this.data.workout}`);
      await workoutDoc.init();
      this.workoutDefinition = new WorkoutDefinition(workoutDoc.data);
    }
  }

  get isCompleted() {
    return this.data.status === workoutAssignmentStatuses.COMPLETED
      || this.data.status === workoutAssignmentStatuses.PARTIALLY_COMPLETED;
  }

  get name() {
    return this.data.workoutContent?.name;
  }

  get user() {
    return this.data.user;
  }

  get coach() {
    return this.data.coach;
  }

  get startDate() {
    return this.data.startDate;
  }

  get workoutStartedDate() {
    return this.data.workoutStartedDate;
  }

  get momentStartDate() {
    // if the workout has started, we use the workoutStartedDate
    const startDate = this.workoutStartedDate || this.startDate;
    // we need to get UTC date to avoid issues with timezones
    const utcStartDateStr = moment(startDate.toDate()).utc().format(DateFormat.DEFAULT_DATE_FORMAT);
    const localStartDate = moment(utcStartDateStr, DateFormat.DEFAULT_DATE_FORMAT).startOf('day');
    return localStartDate;
  }

  get endDate() {
    return this.data.endDate;
  }

  get status() {
    return this.data.status;
  }

  get workout() {
    return this.data.workout;
  }

  get completedBy() {
    return this.data.completedBy;
  }

  get programId() {
    return this.data.programId;
  }

  get workoutContent() {
    return this.data.workoutContent;
  }

  /**
   * Returns the id to be used in a workout assignment doc.
   * This is to be consistent with the already existing id formatting being propagated from Retool.
   * @param {object} userDoc The user doc
   * @param {object} workoutDoc The workout doc
   * @param {object} dueDate The due date (moment date)
   * @returns {string} The id of the workout assignment
   */
  static getId(userDoc, workoutDoc, dueDate) {
    const {
      firstName,
      id: userId,
    } = userDoc;

    const { name: workoutName } = workoutDoc;

    const sanitizedFirstPart = sanitizeForID(firstName || userId);
    const sanitizedWorkoutName = sanitizeForID(workoutName);
    const formattedDueDate = dueDate.format('DDMMMYY');

    return `${sanitizedFirstPart}-${formattedDueDate}-${sanitizedWorkoutName}-${formattedDueDate}-${uuid()}`;
  }

  /**
   * Assigns the workout to the given userId
   * @param {string} userDoc The userId to assign a workout to
   * @param {Object} workoutDoc The workout doc
   * @param {Object} [assignmentData] Extra data for the assignment
   * @param {string} [assignmentData.coachId=null] The id of the coach assigned to supervise this assingment
   * @param {object} [assignmentData.startDate=<Today start of day in UTC format>] Moment date of the start date of
   *                                                                               the assignment
   * @param {object} [assignmentData.endDate=<Today end of day in UTC format>] Moment date of the end date of
   *                                                                           the assignment
   */
  static assignWorkout(userDoc, workoutDoc, {
    coachId = null,
    startDate = moment.utc().startOf('day'),
    endDate = moment.utc().endOf('day'),
    programId = null,
  }) {
    const { id: userId } = userDoc;

    const {
      id: workoutId,
    } = workoutDoc;

    const documentId = WorkoutAssignment.getId(userDoc, workoutDoc, startDate);

    const workoutAssignmentDoc = new WorkoutAssignment(`${firestorePaths.WORKOUT_ASSIGNMENT}/${documentId}`);

    return workoutAssignmentDoc.set({
      user: userId,
      coach: coachId,
      assignedBy: coachId,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      createdAt: new Date(),
      workout: workoutId,
      workoutContent: workoutDoc.data,
      status: workoutAssignmentStatuses.ASSIGNED,
      programId,
    });
  }

  static async getWorkoutAssignmentForUserForDay(user, dayStartMillis) {
    const workoutAssignmentCollection = new Collection(firestorePaths.WORKOUT_ASSIGNMENT,
      {
        query: (ref) => ref
          .where('user', '==', user)
          .where('startDate', '==', new Date(dayStartMillis)),
        createDocument: (source, options) => new WorkoutAssignment(source, options),
      });
    await WorkoutAssignment.initCollection(workoutAssignmentCollection);

    return workoutAssignmentCollection.hasDocs ? workoutAssignmentCollection.docs : [];
  }

  static async getNextWeekWorkoutAssignments() {
    const workoutAssignmentCollection = new Collection(firestorePaths.WORKOUT_ASSIGNMENT,
      {
        query: (ref) => ref
          .where('startDate', '>=', moment.utc().startOf('day').toDate())
          .where('startDate', '<', moment.utc().add(1, 'week').endOf('day').toDate())
          .orderBy('startDate', 'desc'),
        createDocument: (source, options) => new WorkoutAssignment(source, options),
      });
    await WorkoutAssignment.initCollection(workoutAssignmentCollection);

    return workoutAssignmentCollection;
  }

  static async getWorkoutAssignmentsForUser(userId) {
    const workoutAssignmentCollection = new Collection(firestorePaths.WORKOUT_ASSIGNMENT,
      {
        query: (ref) => ref
          .where('user', '==', userId),
        createDocument: (source, options) => new WorkoutAssignment(source, options),
      });
    await WorkoutAssignment.initCollection(workoutAssignmentCollection);
    return workoutAssignmentCollection;
  }

  static async getUserUnStartedWorkoutAssignmentsByWorkoutId(userId, workoutId) {
    const workoutAssignmentCollection = new Collection(firestorePaths.WORKOUT_ASSIGNMENT,
      {
        query: (ref) => ref
          .where('user', '==', userId)
          .where('workout', '==', workoutId)
          .where('status', '==', workoutAssignmentStatuses.ASSIGNED),
        createDocument: (source, options) => new WorkoutAssignment(source, options),
      });
    await WorkoutAssignment.initCollection(workoutAssignmentCollection);
    return workoutAssignmentCollection;
  }

  /**
   * Get the future workout assignments by program id.
   *
   * @param {string} programId The program id
   * @returns {Promise<Collection>} The collection of workout assignments
   */
  static async getFutureAssignmentsByProgramId(programId) {
    const startDate = moment().startOf('day').utc().toDate();
    const workoutAssignmentCollection = new Collection(firestorePaths.WORKOUT_ASSIGNMENT,
      {
        query: (ref) => ref
          .where('programId', '==', programId)
          .where('startDate', '>=', startDate),
        createDocument: (source, options) => new WorkoutAssignment(source, options),
      });
    await WorkoutAssignment.initCollection(workoutAssignmentCollection);
    return workoutAssignmentCollection;
  }

  /**
   * Update the workout assignment document state to partially completed depicting it was forcefully finished.
   *
   * @param {string} gamplePlaySessionPath - Gameplay session path of the assignment completion
   * @param {string} userId - Id of user who forced the completion
   * @returns {Promise<void>}
   */
  async forceCompleteAssignment(gamplePlaySessionPath, userId) {
    this.updateFields({
      status: workoutAssignmentStatuses.PARTIALLY_COMPLETED,
      completedBy: gamplePlaySessionPath,
      forceFinishedBy: userId,
      lastUpdatedTimestamp: new Date(),
    });
  }
}

export default WorkoutAssignment;
export { workoutAssignmentStatuses };
