import {
  BarChart,
  Person,
} from '@mui/icons-material';
import texts from './texts.json';
import CommissionFullDataTable from './CommissionFullDataTable';
import CommissionByUserTable from './CommissionByUserTable';
import { commissionsTabs } from './utils';

const commissionsTabsConfig = {
  [commissionsTabs.COMMISSION_FULL_DATA]: {
    label: texts.tabLabels[commissionsTabs.COMMISSION_FULL_DATA],
    icon: BarChart,
    component: CommissionFullDataTable,
  },
  [commissionsTabs.COMMISSION_BY_USER]: {
    label: texts.tabLabels[commissionsTabs.COMMISSION_BY_USER],
    icon: Person,
    component: CommissionByUserTable,
  },
};

export {
  commissionsTabsConfig,
};
