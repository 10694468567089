import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';

import UserActivity, { UserActivityType } from '../../../Model/UserActivity';
import Select from '../../../../components/Select';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { DateFormat } from '../../../../utils/date';
import ActivityList from '../ActivityListView';
import { getActivityContent } from './util';
import {
  Container,
  FilterWrapper,
} from './styles';
import texts from './texts';

const ACTIVITIES_PER_PAGE = 10;
const ALL_ACTIVITY_TYPE = 'ALL';

const filterOptions = Object.values(UserActivityType)
  .map((type) => ({
    value: type,
    label: texts.filterOptions[type],
  }));

const ClientHistory = ({ clientId }) => {
  const [limit, setLimit] = useState(ACTIVITIES_PER_PAGE);
  const [activitiesCol, setActivitiesCol] = useState({});
  const [activityData, setActivityData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(ALL_ACTIVITY_TYPE);

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const init = async () => {
      const userActivities = await UserActivity.getActivitiesByUser(clientId);
      if (isComponentMountedRef.current) {
        setActivitiesCol(userActivities);
      }
    };
    init();
  }, [
    clientId,
    isComponentMountedRef,
  ]);

  useEffect(() => {
    if (activitiesCol.hasDocs) {
      const activities = activitiesCol.docs.slice();
      const processedActivities = activities.map((activity) => {
        const { content, subContent } = getActivityContent(activity);
        return {
          id: activity.id,
          tag: moment(activity.createdAt).format(DateFormat.DATE_FORMAT_COMMA),
          content,
          subContent,
          type: activity.type,
        };
      });
      setActivityData(processedActivities);
    }
  }, [activitiesCol]);

  const filteredActivities = useMemo(() => {
    if (selectedFilter === ALL_ACTIVITY_TYPE) {
      return activityData;
    }
    return activityData.filter((activity) => activity.type === selectedFilter);
  }, [
    selectedFilter,
    activityData,
  ]);

  const canLoadMore = useMemo(() => !!filteredActivities.length && filteredActivities.length > limit, [
    limit,
    filteredActivities,
  ]);

  const handleFilter = useCallback((event) => {
    setSelectedFilter(event.target.value);
    setLimit(ACTIVITIES_PER_PAGE);
  }, []);

  const handleLoadMore = useCallback(() => {
    setLimit((prevLimit) => prevLimit + ACTIVITIES_PER_PAGE);
  }, []);

  return (
    <Container>
      <FilterWrapper>
        <Select
          options={[
            { value: ALL_ACTIVITY_TYPE, label: texts.filterOptions[ALL_ACTIVITY_TYPE] },
            ...filterOptions,
          ]}
          value={selectedFilter}
          onChange={handleFilter}
          label={texts.filterLabel}
        />
      </FilterWrapper>
      <ActivityList
        activities={filteredActivities.slice(0, limit)}
        canLoadMore={canLoadMore}
        handleLoadMore={handleLoadMore}
      />
    </Container>
  );
};

ClientHistory.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default compose(
  observer,
)(ClientHistory);
