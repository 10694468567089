const sortDates = (fieldName) => (_v1, _v2, a, b) => {
  const dateA = Date.parse(a.api.getRow(a.id)[fieldName]);
  const dateB = Date.parse(b.api.getRow(b.id)[fieldName]);
  if (Number.isNaN(dateA)) return -1;
  if (Number.isNaN(dateB)) return 1;
  return dateA - dateB;
};

const sortCalories = (fieldName, caloriesUnit) => (_v1, _v2, a, b) => {
  const kCalA = a.api.getRow(a.id)[fieldName].replace(caloriesUnit, '').trim();
  const kCalB = b.api.getRow(b.id)[fieldName].replace(caloriesUnit, '').trim();
  if (Number.isNaN(kCalA)) return -1;
  if (Number.isNaN(kCalB)) return 1;
  return kCalA - kCalB;
};

const sortMealtimes = (fieldName) => (_v1, _v2, a, b) => {
  const mealtimeA = a.api.getRow(a.id)[fieldName];
  const mealtimeB = b.api.getRow(b.id)[fieldName];
  if (Number.isNaN(mealtimeA)) return -1;
  if (Number.isNaN(mealtimeB)) return 1;
  return mealtimeA - mealtimeB;
};

export { sortCalories, sortDates, sortMealtimes };
