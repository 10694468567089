import React, {
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  EditOutlined,
} from '@mui/icons-material';

import {
  ImageContainer,
  ImageUploader,
  ImageWrapper,
  StyledPreview,
  StyledEditIcon,
  StyledFileSelectButton,
  StyledImageSearch,
  NoPreviewContainer,
} from './styles';
import texts from './texts.json';

const ImageConfigurator = ({
  imageUrl,
  setImagePreview,
  fileToUpload,
  setFileToUpload,
  isReadOnly,
}) => {
  const onImageSelected = useCallback(({ target }) => {
    const { files } = target;
    if (files.length) {
      const filesArray = [...files];
      // Revoke any previously created URL.
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }

      const [file] = filesArray;
      const url = URL.createObjectURL(file);

      setImagePreview(url);
      setFileToUpload(file);
    }
  }, [
    imageUrl,
    setImagePreview,
    setFileToUpload,
  ]);

  const renderImagePreview = () => {
    if (!imageUrl && !fileToUpload) {
      return (
        <NoPreviewContainer>
          <StyledImageSearch />
          {texts.newUpload}
        </NoPreviewContainer>
      );
    }

    return (
      <>
        <StyledEditIcon>
          <EditOutlined />
        </StyledEditIcon>
        <StyledPreview src={imageUrl} />
      </>
    );
  };

  return (
    <ImageContainer>
      <ImageUploader>
        <ImageWrapper>
          <StyledFileSelectButton
            onSelect={onImageSelected}
            multiple={false}
            extraInputProps={{ accept: 'image/jpeg' }}
            extraButtonProps={{ disabled: isReadOnly }}
          >
            {renderImagePreview()}
          </StyledFileSelectButton>
        </ImageWrapper>
      </ImageUploader>
    </ImageContainer>
  );
};

ImageConfigurator.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  setImagePreview: PropTypes.func.isRequired,
  fileToUpload: PropTypes.object,
  setFileToUpload: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

ImageConfigurator.defaultProps = {
  fileToUpload: null,
  isReadOnly: false,
};

export default ImageConfigurator;
