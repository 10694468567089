import pages from '../../config/pages';
import { ConfigurationType, configurationPagesConfig } from '../../config/configurationPages';
import Config from '../../pages/Config';

const routes = [
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.PAYMENT].path,
    component: Config,
  },
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.CHECKOUT_PAGE].path,
    component: Config,
  },
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.WELCOME_MESSAGE].path,
    component: Config,
  },
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.HABITS].path,
    component: Config,
  },
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.CHECK_IN].path,
    component: Config,
  },
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.PRODUCTS].path,
    component: Config,
  },
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.PROFILE_SETTINGS].path,
    component: Config,
  },
  {
    pageId: pages.CONFIG,
    configPath: configurationPagesConfig[ConfigurationType.OTHER_SETTINGS].path,
    component: Config,
  },
];

export default routes;
