import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';
import { v4 as uuidv4 } from 'uuid';

import { TimeDurationFormat, formatDuration } from '../../../../utils/time';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import { DateFormat } from '../../../../utils/date';
import WorkoutFeedView from '../../../Model/WorkoutFeedView';
import ActivityList from '../ActivityListView';
import texts from './texts';

const ACTIVITIES_PER_PAGE = 10;

const WorkoutHistory = ({ clientId }) => {
  const [workoutsCollection, setWorkoutsCollection] = useState({});
  const [limit, setLimit] = useState(ACTIVITIES_PER_PAGE);
  const isComponentMountedRef = useComponentMounted();

  const canLoadMore = useMemo(() => workoutsCollection?.docs?.length === limit, [limit, workoutsCollection]);

  useEffect(() => {
    const loadworkoutHistoryCollection = async () => {
      const workoutHistoryCollection = await WorkoutFeedView.getCompletedWorkoutHistoryByUser(
        clientId,
        limit,
      );
      if (isComponentMountedRef.current) {
        setWorkoutsCollection(workoutHistoryCollection);
      }
    };
    loadworkoutHistoryCollection();
  }, [
    clientId,
    isComponentMountedRef,
    limit,
  ]);

  const activities = useMemo(() => {
    if (workoutsCollection && workoutsCollection.docs) {
      return workoutsCollection.docs.map((historyItem) => ({
        key: `${historyItem.title}-${uuidv4()}`,
        tag: moment(historyItem.startDate).format(DateFormat.DATE_FORMAT_COMMA),
        content:
          format(texts.completedWorkoutText, {
            type: texts.types[historyItem.type],
            title: historyItem.title,
          })
          + (historyItem.duration
            ? format(texts.duration, {
              duration: formatDuration(
                historyItem.duration,
                TimeDurationFormat.CLOCK_FORMAT,
              ),
            })
            : ''),
        subContent: historyItem.comment
          ? format(texts.comment, { comment: historyItem.comment })
          : null,
      }));
    }
    return [];
  }, [workoutsCollection]);

  const handleLoadMore = useCallback(() => {
    setLimit((prevLimit) => prevLimit + ACTIVITIES_PER_PAGE);
  }, []);

  return (
    <ActivityList
      activities={activities}
      canLoadMore={canLoadMore}
      handleLoadMore={handleLoadMore}
    />
  );
};

WorkoutHistory.propTypes = {
  clientId: PropTypes.string.isRequired,
};

export default WorkoutHistory;
