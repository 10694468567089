import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  MenuItem,
} from '@mui/material';

import DialogModal from '../../../../../components/DialogModal/DialogModal';
import InputLabel from '../../../../../components/v2/InputLabel';
import { SocialPlatform } from '../../../../Model/CoachOnboarding';
import { validationSchema } from './validation';
import {
  Container,
  Title,
  StyledTextField,
  StyledSelectField,
} from './styles';
import texts from './texts.json';

const NewCoachModal = ({
  onAddNewCoach,
  ...modalProps
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [primarySocial, setPrimarySocial] = useState(SocialPlatform.INSTAGRAM);
  const [primaryHandle, setPrimaryHandle] = useState('');
  const [alternatePlatform, setAlternatePlatform] = useState('');
  const [errors, setErrors] = useState({});

  const isAlternatePlatformSelected = primarySocial === SocialPlatform.OTHER;

  const renderSocialOptions = useMemo(() => (
    Object.entries(SocialPlatform).map(([key, social]) => (
      <MenuItem key={key} value={social}>{social}</MenuItem>
    ))
  ), []);

  const onSave = useCallback(() => {
    try {
      const formData = {
        name,
        email,
        primarySocial: isAlternatePlatformSelected ? alternatePlatform : primarySocial,
        primaryHandle,
      };
      validationSchema.validateSync(formData, { abortEarly: false });
      onAddNewCoach(email, name, isAlternatePlatformSelected ? alternatePlatform : primarySocial, primaryHandle);
    } catch (err) {
      const errorMessages = err.inner.reduce((messages, errorObj) => ({
        ...messages,
        [errorObj.path]: errorObj.message,
      }), {});
      setErrors(errorMessages);
    }
  }, [
    name,
    email,
    primarySocial,
    primaryHandle,
    isAlternatePlatformSelected,
    alternatePlatform,
    onAddNewCoach,
  ]);

  // Reset the state when the modal is opened
  useEffect(() => {
    if (modalProps.isOpen) {
      setName('');
      setEmail('');
      setPrimarySocial(SocialPlatform.INSTAGRAM);
      setPrimaryHandle('');
      setAlternatePlatform('');
      setErrors({});
    }
  }, [modalProps.isOpen]);

  return (
    <DialogModal
      open={modalProps.isOpen}
      onClose={modalProps.onDidDismiss}
      maxWidth="sm"
      fullWidth
    >
      <Container>
        <Title>{texts.title}</Title>
        <StyledTextField
          label={texts.name}
          variant="outlined"
          onChange={(e) => setName(e.target.value)}
          error={!!errors.name}
          helperText={errors.name}
        />
        <StyledTextField
          label={texts.email}
          variant="outlined"
          onChange={(e) => setEmail(e.target.value?.toLowerCase())}
          error={!!errors.email}
          helperText={errors.email}
        />
        <InputLabel>{texts.primarySocial}</InputLabel>
        <StyledSelectField
          value={primarySocial}
          onChange={(e) => setPrimarySocial(e.target.value)}
        >
          {renderSocialOptions}
        </StyledSelectField>
        {isAlternatePlatformSelected && (
        <StyledTextField
          label={texts.otherSocial}
          variant="outlined"
          onChange={(e) => setAlternatePlatform(e.target.value.toUpperCase())}
          error={!!errors.primarySocial}
          helperText={errors.primarySocial}
        />
        )}
        <StyledTextField
          label={texts.primaryHandle}
          variant="outlined"
          onChange={(e) => setPrimaryHandle(e.target.value)}
          error={!!errors.primaryHandle}
          helperText={errors.primaryHandle}
        />
        <Button
          onClick={onSave}
          variant="contained"
        >
          {texts.save}
        </Button>
      </Container>
    </DialogModal>
  );
};

NewCoachModal.propTypes = {
  onAddNewCoach: PropTypes.func.isRequired,
};

export default NewCoachModal;
