import styled, { css } from 'styled-components';

import { ReactComponent as ActivityFeedIcon } from '../../assets/icons/menuIcons/activity-feed.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/menuIcons/chat.svg';
import { ReactComponent as MealPlannerIcon } from '../../assets/icons/menuIcons/meal-planner.svg';
import { ReactComponent as YourClientIcon } from '../../assets/icons/menuIcons/your-clients.svg';
import { ReactComponent as WorkoutManagerIcon } from '../../assets/icons/menuIcons/exercise-editor.svg';
import { ReactComponent as LeadIcon } from '../../assets/icons/menuIcons/leads.svg';
import { ReactComponent as ConfigurationIcon } from '../../assets/icons/menuIcons/configurations.svg';
import { ReactComponent as ProductConfigIcon } from '../../assets/icons/menuIcons/dollar.svg';

import {
  ReactComponent as StripeConfigIcon,
} from '../../assets/icons/menuIcons/configurationMenuIcons/stripe.svg';
import {
  ReactComponent as WelcomeMessageConfigIcon,
} from '../../assets/icons/menuIcons/configurationMenuIcons/welcome-message.svg';
import {
  ReactComponent as HabitConfigIcon,
} from '../../assets/icons/menuIcons/configurationMenuIcons/habits-customisation.svg';
import {
  ReactComponent as CheckInConfigIcon,
} from '../../assets/icons/menuIcons/configurationMenuIcons/check-in-customisation.svg';
import {
  ReactComponent as CheckoutPageConfigIcon,
} from '../../assets/icons/menuIcons/configurationMenuIcons/checkout-page.svg';
import {
  ReactComponent as CoachProfileIcon,
} from '../../assets/icons/menuIcons/configurationMenuIcons/coach-settings.svg';
import {
  ReactComponent as SettingsIcon,
} from '../../assets/icons/menuIcons/configurationMenuIcons/other-settings.svg';

const iconStyle = css`
  height: 25px;
  width: 25px;
`;

const menuIconStyle = css`
  height: 20px;
  width: 20px;
`;

const StyledActivityFeedIcon = styled(ActivityFeedIcon)`
  ${iconStyle};
`;

const StyledChatIcon = styled(ChatIcon)`
  ${iconStyle};
`;

const StyledMealPlannerIcon = styled(MealPlannerIcon)`
  ${iconStyle};
`;

const StyledYourClientIcon = styled(YourClientIcon)`
  ${iconStyle};
`;

const StyledWorkoutsManagerIcon = styled(WorkoutManagerIcon)`
  ${iconStyle};
`;

const StyledLeadIcon = styled(LeadIcon)`
  ${iconStyle};
`;

const StyledConfigurationIcon = styled(ConfigurationIcon)`
  ${iconStyle};
`;

const StyledStripeConfigIcon = styled(StripeConfigIcon)`
  ${menuIconStyle};
`;

const StyledWelcomeMessageConfigIcon = styled(WelcomeMessageConfigIcon)`
  ${menuIconStyle};
`;

const StyledHabitConfigIcon = styled(HabitConfigIcon)`
  ${menuIconStyle};
`;

const StyledCheckInConfigIcon = styled(CheckInConfigIcon)`
  ${menuIconStyle};
`;

const StyledCheckoutPageConfigIcon = styled(CheckoutPageConfigIcon)`
  ${menuIconStyle};
`;

const StyledProductConfigIcon = styled(ProductConfigIcon)`
  ${menuIconStyle};
`;

const StyledProfileIcon = styled(CoachProfileIcon)`
  ${menuIconStyle};
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  ${menuIconStyle};
`;

export {
  StyledActivityFeedIcon as ActivityFeedIcon,
  StyledChatIcon as ChatIcon,
  StyledMealPlannerIcon as MealPlannerIcon,
  StyledYourClientIcon as YourClientIcon,
  StyledWorkoutsManagerIcon as WorkoutsManagerIcon,
  StyledLeadIcon as LeadIcon,
  StyledConfigurationIcon as ConfigurationIcon,
  StyledStripeConfigIcon,
  StyledWelcomeMessageConfigIcon,
  StyledHabitConfigIcon,
  StyledCheckInConfigIcon,
  StyledCheckoutPageConfigIcon,
  StyledProductConfigIcon,
  StyledProfileIcon,
  StyledSettingsIcon,
};
