import pages, { pagesConfig } from '../../config/pages';
import useToolsNavigation from '../../hooks/useToolsNavigation';

const useAdminSideBarConfig = () => {
  const { navigateTo } = useToolsNavigation();

  const entries = [
    {
      pageId: pages.CRX_COACH_ONBOARDING,
      icon: pagesConfig[pages.CRX_COACH_ONBOARDING].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_COACH_ONBOARDING].path),
      title: pagesConfig[pages.CRX_COACH_ONBOARDING].title,
    },
    {
      pageId: pages.ANALYTICS,
      icon: pagesConfig[pages.ANALYTICS].icon,
      onClick: () => navigateTo(pagesConfig[pages.ANALYTICS].path),
      title: pagesConfig[pages.ANALYTICS].title,
    },
    {
      pageId: pages.CRX_COMMISSIONS,
      icon: pagesConfig[pages.CRX_COMMISSIONS].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_COMMISSIONS].path),
      title: pagesConfig[pages.CRX_COMMISSIONS].title,
    },
    {
      pageId: pages.FORMS,
      icon: pagesConfig[pages.FORMS].icon,
      onClick: () => navigateTo(pagesConfig[pages.FORMS].path),
      title: pagesConfig[pages.FORMS].title,
    },
    {
      pageId: pages.CRX_USER_MANAGEMENT,
      icon: pagesConfig[pages.CRX_USER_MANAGEMENT].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_USER_MANAGEMENT].path),
      title: pagesConfig[pages.CRX_USER_MANAGEMENT].title,
    },
    {
      pageId: pages.CRX_COACH_CONFIGS,
      icon: pagesConfig[pages.CRX_COACH_CONFIGS].icon,
      onClick: () => navigateTo(pagesConfig[pages.CRX_COACH_CONFIGS].path),
      title: pagesConfig[pages.CRX_COACH_CONFIGS].title,
    },
  ];

  return {
    entries,
  };
};

export default useAdminSideBarConfig;
