import React from 'react';
import PropTypes from 'prop-types';

import Table from '../Table';
import { commissionsTabs } from './utils';
import { Preset } from './presets';
import texts from './texts.json';
import { StyledAlert } from './styles';

const CommissionByUserTable = ({
  rows,
  columns,
  isLoading,
  selectedTab,
  selectedPreset,
}) => {
  if (selectedTab === commissionsTabs.COMMISSION_BY_USER
    && (selectedPreset === Preset.INSIDE_SALES_LEAD || selectedPreset === Preset.CRX_LEAD)) {
    return <StyledAlert severity="warning">{texts.selectDifferentFilter}</StyledAlert>;
  }

  return (
    <Table
      rows={rows}
      columns={columns}
      isLoading={isLoading}
    />
  );
};

CommissionByUserTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  selectedTab: PropTypes.string.isRequired,
  selectedPreset: PropTypes.string.isRequired,
};

CommissionByUserTable.defaultProps = {
  isLoading: true,
};

export default CommissionByUserTable;
