import texts from './text.json';

const formatText = (text) => {
  if (!text) return '';

  if (text in texts) {
    return texts[text] || text;
  }

  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export {
  formatText,
};
