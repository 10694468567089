import { Collection, Document } from 'firestorter';

import BaseDocument from '../../Model/BaseDocument';
import { firestorePaths } from '../../utils/paths';
import { CoachOnboardingStatus } from '../utils/coachOnboarding';

const SocialPlatform = {
  INSTAGRAM: 'INSTAGRAM',
  FACEBOOK: 'FACEBOOK',
  YOUTUBE: 'YOUTUBE',
  TIKTOK: 'TIKTOK',
  OTHER: 'OTHER',
};

class CoachOnboarding extends BaseDocument {
  get name() {
    return this.data.name;
  }

  get email() {
    return this.data.email;
  }

  get primarySocial() {
    return this.data.primarySocial;
  }

  get primaryHandle() {
    return this.data.primaryHandle;
  }

  get coach() {
    return this.data.coach;
  }

  get crxId() {
    return this.data.crxId;
  }

  get isOnboarded() {
    return !!this.data.isOnboarded;
  }

  get status() {
    return this.data.status;
  }

  get createdAt() {
    return this.data?.createdAt?.toDate();
  }

  get lastStepCompleted() {
    return this.data.lastStepCompleted;
  }

  get coachHasStripeAccountId() {
    return !!this.data.coachHasStripeAccountId;
  }

  get coachHasAvatarUrl() {
    return !!this.data.coachHasAvatarUrl;
  }

  static async getCoachOnboardingByCoachId(coachId) {
    const coachOnboardingCollection = new Collection(firestorePaths.COACH_ONBOARDING, {
      createDocument: (src, options) => new CoachOnboarding(src, options),
      query: (ref) => ref.where('coach', '==', coachId),
    });
    await coachOnboardingCollection.fetch();

    if (coachOnboardingCollection.docs.length === 1) {
      return coachOnboardingCollection.docs[0];
    }
    return null;
  }

  static async getCoachesOnboardingByCrxId(crx) {
    const coachOnboardingCollection = new Collection(() => firestorePaths.COACH_ONBOARDING, {
      createDocument: (src, opts) => new CoachOnboarding(src, opts),
      query: (ref) => ref.where('crx', '==', crx),
    });
    await coachOnboardingCollection.fetch();
    return coachOnboardingCollection;
  }

  static async createNewCoachonboardingDoc(crx, email, name, primarySocial, primaryHandle) {
    const coachOnboardingCollection = new Collection(firestorePaths.COACH_ONBOARDING);
    coachOnboardingCollection.add({
      crx,
      email,
      name,
      primarySocial,
      primaryHandle,
      status: CoachOnboardingStatus.COACH_EMAIL_ADDED,
      createdAt: new Date(),
    });
  }

  static async removeCoachOnboardingDoc(coachOnboardingDocId) {
    const coachOnboardingCollection = new Document(`${firestorePaths.COACH_ONBOARDING}/${coachOnboardingDocId}`);
    return coachOnboardingCollection.delete();
  }
}

export default CoachOnboarding;
export { SocialPlatform };
