import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import { useRouteMatch } from 'react-router-dom';

import LoadingPage from '../../../components/LoadingPage';
import useComponentMounted from '../../../hooks/useComponentMounted';
import CoachOnboarding from '../../Model/CoachOnboarding';
import OnboardingTable from './components/OnboardingTable';
import NewCoachModal from './components/NewCoachModal';

import {
  Container,
  AddNewCoachButton,
  ButtonsContainer,
} from './styles';
import text from './text.json';

const CRXCoachOnboarding = () => {
  const [isReady, setIsReady] = useState(false);
  const [coachOnboardingCollection, setCoachOnboardingCollection] = useState({ docs: [] });
  const [isNewCoachModalOpen, setIsNewCoachModalOpen] = useState(false);

  const {
    params: {
      userId: crx,
    },
  } = useRouteMatch();

  const isComponentMountedRef = useComponentMounted();

  useEffect(() => {
    const getOnboardingDocs = async () => {
      const collection = await CoachOnboarding.getCoachesOnboardingByCrxId(crx);

      if (isComponentMountedRef.current) {
        setCoachOnboardingCollection(collection);
        setIsReady(true);
      }
    };

    if (!isReady) {
      getOnboardingDocs();
    }
  }, [
    isComponentMountedRef,
    isReady,
    crx,
  ]);

  const onAddNewCoach = useCallback(async (email, name, primarySocial, primaryHandle) => {
    await CoachOnboarding.createNewCoachonboardingDoc(crx, email, name, primarySocial, primaryHandle);

    if (isComponentMountedRef.current) {
      setIsNewCoachModalOpen(false);
    }
  }, [
    crx,
    isComponentMountedRef,
  ]);

  if (!isReady) {
    return <LoadingPage />;
  }

  return (
    <Container>
      <ButtonsContainer>
        <AddNewCoachButton
          variant="contained"
          onClick={() => setIsNewCoachModalOpen(true)}
        >
          {text.addNewCoach}
        </AddNewCoachButton>
      </ButtonsContainer>
      <OnboardingTable rows={coachOnboardingCollection.docs} />
      <NewCoachModal
        isOpen={isNewCoachModalOpen}
        onDidDismiss={() => setIsNewCoachModalOpen(false)}
        onAddNewCoach={onAddNewCoach}
      />
    </Container>
  );
};

export default compose(
  observer,
)(CRXCoachOnboarding);
