import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import format from 'string-template';
import PropTypes from 'prop-types';

import useComponentMounted from '../../../hooks/useComponentMounted';
import FirebaseContext from '../../../context/FirebaseContext';
import MsgTemplate from '../../../Model/MsgTemplate';
import { pathPlaceholder } from '../../../utils/paths';
import LoadingOverlay from '../../components/LoadingOverlay';
import TemplateView from '../../components/Templates/components/TemplateView';
import useStorage from '../../../hooks/useStorage';
import useSessionStore from '../../../hooks/useSessionStore';
import { storagePaths } from '../../../utils/firebasePaths';
import {
  getFileCategory,
  getFileExtension,
  getFileName,
  getFileUrl,
} from '../../../utils/file';
import {
  Card, CardsContainer,
} from '../../../components/v2/Card';
import Coach from '../../../Model/Coach';
import NonISCoachView from '../ISCoachConfigurations/NonISCoachView';
import AutoComplete from '../../components/AutoComplete';
import StyledTextField from '../../../components/v2/TextField';
import {
  MessageTemplateSelectWrapper,
  NoTemplateSelectedText,
} from './styles';
import texts from './texts.json';
import { getTemplateConversionRates } from './utils';

const MessageTemplates = ({ coach, isCRX }) => {
  const {
    firebase: {
      remote,
    },
  } = useContext(FirebaseContext);

  const [isLoading, setIsLoading] = useState(false);
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [coachMsgTemplates, setCoachMsgTemplates] = useState([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [templateConversionRates, setTemplateConversionRates] = useState({});
  const [selectedTemplateList, setSelectedTemplateList] = useState();
  const [msgBody, setMsgBody] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [messageTemplateEnabled, setMessageTemplateEnabled] = useState();
  const [coachDoc, setCoachDoc] = useState(null);

  const isComponentMountedRef = useComponentMounted();

  const { uploadAttachment } = useStorage();
  const { authUser } = useSessionStore();

  useEffect(() => {
    const init = async () => {
      if (coach) {
        const coachDocument = await Coach.getCoachById(coach.id);
        if (isComponentMountedRef.current) {
          setCoachDoc(coachDocument);
        }
      }
    };
    init();
  }, [coach, isComponentMountedRef]);

  // Load default message templates
  useEffect(() => {
    const loadDefaultTemplates = async () => {
      const defaultTemplatesCollection = await MsgTemplate.getDefaultTemplates();
      if (isComponentMountedRef.current) {
        setDefaultTemplates(defaultTemplatesCollection.docs);
      }
    };
    loadDefaultTemplates();
  }, [isComponentMountedRef]);

  const defaultTemplateOptions = useMemo(() => (
    defaultTemplates.map((template) => ({ id: template.id, label: template.name }))
  ), [defaultTemplates]);

  // Load coach's message templates when selecting a coach
  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      setSelectedTemplateName('');
      const coachTemplateListCollection = await MsgTemplate.getTemplatesByCoach(coach.id);
      if (isComponentMountedRef.current) {
        setIsLoading(false);
        setCoachMsgTemplates(coachTemplateListCollection.docs);
      }
    };
    if (coach && isComponentMountedRef.current) {
      init();
    }
  }, [
    isComponentMountedRef,
    coach,
  ]);

  // Current Template of selected template name
  const currentTemplate = useMemo(
    () => selectedTemplateList?.find((template) => template.current === true || template.isDefault === true),
    [selectedTemplateList],
  );

  // Template history of selected template name
  const templateHistory = useMemo(
    () => selectedTemplateList?.sort((first, second) => second.createdAt - first.createdAt),
    [selectedTemplateList],
  );

  const loadConversionRates = useCallback(async () => {
    setIsLoading(true);
    const conversionRates = await getTemplateConversionRates(remote, coach.id, selectedTemplateName.label);
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      setTemplateConversionRates(conversionRates);
    }
  }, [
    isComponentMountedRef,
    remote,
    coach,
    selectedTemplateName,
  ]);

  /**
   * When selecting a template name, set templates list that's belongs to selected template name.
   * If coach does't have template for that name, default template set as the template list
   */
  useEffect(() => {
    if (selectedTemplateName) {
      let selectedTemplates = coachMsgTemplates.filter((template) => template.name === selectedTemplateName.label);
      if (!selectedTemplates.length) {
        selectedTemplates = defaultTemplates.filter((template) => template.name === selectedTemplateName.label);
      }
      setSelectedTemplateList(selectedTemplates);
      setFileToUpload(null);
      setTemplateConversionRates({});
    }
  }, [
    selectedTemplateName,
    coachMsgTemplates,
    defaultTemplates,
  ]);

  useEffect(() => {
    setMsgBody(currentTemplate?.msgBody);
    setMessageTemplateEnabled(currentTemplate?.isEnabled);
  }, [currentTemplate]);

  const onTemplateSelect = useCallback((selectedTemplate) => {
    if (selectedTemplate) {
      setSelectedTemplateName(selectedTemplate);
      setIsFileRemoved(false);
    } else {
      setSelectedTemplateName('');
    }
  }, []);

  const saveTemplate = useCallback(async () => {
    setIsLoading(true);
    let attachmentUrl = null;
    let attachmentCategory = null;
    const { version } = currentTemplate;
    if (fileToUpload) {
      const fileExt = getFileExtension(fileToUpload.name);
      const fileName = getFileName(fileToUpload.name);
      const fileNameWithVersion = `${fileName}_v${version}`;
      const fileType = fileToUpload.type;
      const storagePath = format(storagePaths.IS_MSG_ATTACHMENTS, {
        [pathPlaceholder.COACH_ID]: coach.id,
      });
      const { publicUrl } = await uploadAttachment(
        getFileUrl(fileToUpload),
        fileExt,
        storagePath,
        fileNameWithVersion,
        fileType,
      );
      attachmentUrl = publicUrl;
      attachmentCategory = getFileCategory(fileToUpload);
    }

    const newTemplateVersion = {
      ...currentTemplate.data,
      msgBody,
      ...(attachmentUrl && { attachmentUrl }),
      ...(attachmentCategory && { attachmentCategory }),
      ...(isFileRemoved && { attachmentUrl: null, attachmentCategory: null }),
      isDefault: false,
      createdAt: new Date(),
      coach: coach.id,
      createdByUserName: authUser.displayName,
      createdByUser: authUser.uid,
      isEnabled: messageTemplateEnabled,
      current: true,
    };

    if (currentTemplate.isDefault) {
      // if default template, create a new one for coach
      await MsgTemplate.addNewTemplate(newTemplateVersion, version);
    } else {
      // Create new message template with updated details, when user changes msg body or attachment or remove attachment
      const shouldCreateNewTemplate = fileToUpload || (currentTemplate.msgBody !== msgBody) || isFileRemoved;
      // Update current template, set current property to false if a new template is created
      await currentTemplate.update({
        updatedAt: new Date(),
        isEnabled: messageTemplateEnabled,
        current: !shouldCreateNewTemplate,
      });
      if (shouldCreateNewTemplate) {
        await MsgTemplate.addNewTemplate(newTemplateVersion, version + 1);
      }
    }

    const templateList = await MsgTemplate.getTemplatesByCoach(coach.id);
    if (isComponentMountedRef.current) {
      setIsLoading(false);
      setCoachMsgTemplates(templateList.docs);
      setIsFileRemoved(false);
    }
  }, [
    fileToUpload,
    isComponentMountedRef,
    msgBody,
    coach,
    uploadAttachment,
    messageTemplateEnabled,
    authUser,
    currentTemplate,
    isFileRemoved,
  ]);

  if (coachDoc && !coachDoc.isEnrolledInISProgram) {
    return <NonISCoachView coachDoc={coachDoc} isCRX={isCRX} />;
  }

  return (
    <>
      <CardsContainer $fullWidth>
        <MessageTemplateSelectWrapper>
          <AutoComplete
            id="message-template-select"
            options={defaultTemplateOptions}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder={texts.placeholder}
                label={texts.label}
              />
            )}
            value={selectedTemplateName}
            onChange={onTemplateSelect}
          />
        </MessageTemplateSelectWrapper>
        {selectedTemplateName ? (
          <Card>
            <TemplateView
              key={currentTemplate?.id}
              template={currentTemplate}
              templateHistory={templateHistory}
              templateConversionRates={templateConversionRates}
              loadConversionRates={loadConversionRates}
              msgBody={msgBody}
              onMsgBodyChange={setMsgBody}
              onSaveTemplate={saveTemplate}
              file={fileToUpload}
              onFileChange={setFileToUpload}
              isMessageTemplateEnabled={messageTemplateEnabled}
              onChangeMessageTemplateState={setMessageTemplateEnabled}
              isFileRemoved={isFileRemoved}
              onRemoveFile={setIsFileRemoved}
              isEmailTemplateView={false}
            />
          </Card>
        ) : (
          <Card>
            <NoTemplateSelectedText>{texts.selectMessageTemplate}</NoTemplateSelectedText>
          </Card>
        )}
      </CardsContainer>
      <LoadingOverlay isLoading={isLoading} />
    </>
  );
};

MessageTemplates.propTypes = {
  coach: PropTypes.object.isRequired,
  isCRX: PropTypes.bool,
};

MessageTemplates.defaultProps = {
  isCRX: false,
};

export default MessageTemplates;
