import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import format from 'string-template';

import FirebaseContext from '../../../../context/FirebaseContext';
import CoachesListContext from '../../../context/CoachesListContext';
import LoggedInUserContext from '../../../../context/LoggedInUserContext';
import Coach from '../../../../Model/Coach';
import useComponentMounted from '../../../../hooks/useComponentMounted';
import {
  Card,
  CardsContainer,
  Container,
} from '../../../../components/v2/Card';
import { StyledTextFieldContainer } from '../../../../components/Inputs';
import InputLabel from '../../../../components/v2/InputLabel';
import { SaveButton } from '../../../../components/Button/ActionButtons';
import useToast from '../../../hooks/useToast';
import RadioButtonGroup from '../../../components/RadioButtonGroup';
import LoadingOverlay from '../../../components/LoadingOverlay';
import AutoComplete from '../../../components/AutoComplete';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
  StyledLoadingPage,
  HelperText,
  UpdatedSettings,
  UpdatedSettingItem,
} from '../styles';

import {
  SettingOption,
  getPublicChannelsList,
  joinSlackChannel,
} from './utils';
import texts from './texts.json';

const SlackNotifications = ({
  coach: {
    id: coachId,
    label: coachName,
  },
}) => {
  const { firebase: { remote } } = useContext(FirebaseContext);
  const {
    coachesCollection,
  } = useContext(CoachesListContext);
  const { userDoc } = useContext(LoggedInUserContext);
  const isComponentMountedRef = useComponentMounted();
  const { showToast } = useToast();

  const [channelsList, setChannelsList] = useState([]);
  const [coachDoc, setCoachDoc] = useState(null);
  const [isCoachDocReady, setIsCoachDocReady] = useState(false);
  const [isChannelsReady, setIsChannelsReady] = useState(false);
  const [coachChannel, setCoachChannel] = useState('');
  const [isNotificationsEnabled, setIsNotificationsEnabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const init = async () => {
      const coachingChannels = await getPublicChannelsList(remote);
      if (isComponentMountedRef.current) {
        setChannelsList(coachingChannels);
        setIsChannelsReady(true);
      }
    };
    init();
  }, [
    isComponentMountedRef,
    remote,
  ]);

  useEffect(() => {
    const getCoach = async () => {
      setIsCoachDocReady(false);
      const coach = await Coach.getCoachById(coachId);
      if (isComponentMountedRef.current) {
        setCoachDoc(coach);
        setCoachChannel(coach.slackChannelId);
        setIsNotificationsEnabled(coach.isSlackNotificationsEnabled);
        setIsCoachDocReady(true);
      }
    };
    if (coachId) {
      getCoach();
    }
  }, [
    isComponentMountedRef,
    coachId,
  ]);

  // Hide errors when settings are changed
  useEffect(() => {
    setShowError(false);
  }, [
    coachChannel,
    isNotificationsEnabled,
  ]);

  const isSlackChannelChanged = !!coachDoc && coachChannel !== coachDoc.slackChannelId;
  const isNotificationToggled = !!coachDoc && isNotificationsEnabled !== coachDoc.isSlackNotificationsEnabled;

  const errors = useMemo(() => {
    if (!coachChannel && isNotificationsEnabled) {
      return texts.errors.required;
    }
    if (!isSlackChannelChanged && !isNotificationToggled) {
      return texts.errors.noChange;
    }
    return '';
  }, [
    coachChannel,
    isNotificationsEnabled,
    isSlackChannelChanged,
    isNotificationToggled,
  ]);

  const dialogContent = useMemo(() => ({
    slackChannel: isSlackChannelChanged
      ? format(texts.channelChange, {
        channelName: channelsList.find((channel) => channel.id === coachChannel)?.label,
      })
      : '',
    notifications: isNotificationToggled ? texts.notificationChange : '',
  }), [
    channelsList,
    coachChannel,
    isSlackChannelChanged,
    isNotificationToggled,
  ]);

  const handleSaveClick = useCallback(() => {
    if (errors) {
      setShowError(true);
      return;
    }
    setShowModal(true);
  }, [errors]);

  const handleSave = useCallback(async () => {
    try {
      setShowModal(false);
      setIsProcessing(true);
      // Only join channel if the channel was changed
      if (isSlackChannelChanged) {
        const error = await joinSlackChannel(remote, coachChannel);
        if (error) {
          throw new Error(error);
        }
      }
      await coachDoc.updateFields({
        slackChannelId: coachChannel,
        slackNotificationsEnabled: isNotificationsEnabled,
        lastUpdatedByUserName: userDoc.name,
      });
      showToast(texts.saveSuccessful);
    } catch (error) {
      showToast(format(texts.saveFailed, { error }), { error: true });
    }
    setIsProcessing(false);
  }, [
    remote,
    coachDoc,
    isSlackChannelChanged,
    isNotificationsEnabled,
    coachChannel,
    showToast,
    userDoc,
  ]);

  const availableChannels = useMemo(() => {
    if (channelsList.length === 0) {
      return [];
    }

    const assignedChannels = new Set();
    coachesCollection.docs.forEach(({ slackChannelId }) => {
      if (slackChannelId && isCoachDocReady && coachDoc.slackChannelId !== slackChannelId) {
        assignedChannels.add(slackChannelId);
      }
    });

    return channelsList
      .filter((channel) => !assignedChannels.has(channel.id) && channel.name.startsWith('coaching'))
      .map((channel) => ({ id: channel.id, label: channel.name }));
  }, [channelsList, coachDoc, coachesCollection, isCoachDocReady]);

  if (!isChannelsReady || !isCoachDocReady) {
    return <StyledLoadingPage />;
  }

  return (
    <CardsContainer $fullWidth>
      <Card>
        <Container>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.slackChannel}</InputLabel>
            <AutoComplete
              initialValue={coachChannel}
              options={availableChannels}
              onChange={(data) => setCoachChannel(data?.id || '')}
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer $withMargin>
            <InputLabel>{texts.notifications}</InputLabel>
            <RadioButtonGroup
              options={[
                {
                  label: texts.enabled,
                  value: SettingOption.ENABLED,
                },
                {
                  label: texts.disabled,
                  value: SettingOption.DISABLED,
                },
              ]}
              selectedOption={isNotificationsEnabled ? SettingOption.ENABLED : SettingOption.DISABLED}
              onOptionChange={(value) => setIsNotificationsEnabled(value === SettingOption.ENABLED)}
            />
          </StyledTextFieldContainer>
        </Container>
        {showError && (<HelperText error>{errors}</HelperText>)}
        <SaveButton
          onClick={handleSaveClick}
          $fitToContent
        >
          {texts.save}
        </SaveButton>
      </Card>
      <ConfirmDialog
        isOpen={showModal}
        onConfirm={handleSave}
        onCancel={() => setShowModal(false)}
        dialogTexts={{
          title: format(texts.confirmTitle, { coach: coachName }),
          content: (
            <UpdatedSettings>
              {dialogContent.slackChannel && <UpdatedSettingItem>{dialogContent.slackChannel}</UpdatedSettingItem>}
              {dialogContent.notifications && <UpdatedSettingItem>{dialogContent.notifications}</UpdatedSettingItem>}
            </UpdatedSettings>
          ),
        }}
      />
      <LoadingOverlay isLoading={isProcessing} />
    </CardsContainer>
  );
};

SlackNotifications.propTypes = {
  coach: PropTypes.object.isRequired,
};

export default compose(
  observer,
)(SlackNotifications);
