import styled from 'styled-components';
import { Box, Paper } from '@mui/material';

import Button from '../../../../../components/Button/Button';

const StyledButton = styled(Button)`
  height: 40px;
  font-size: 14px;
  text-align: initial;
  width: unset;
`;

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const StyledPaper = styled(Paper)`
  min-width: 600px;
  height: 100%;
`;

export {
  StyledButton,
  StyledBox,
  IframeContainer,
  StyledPaper,
};
