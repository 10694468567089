import { UserActivityType } from '../../../Model/UserActivity';

const texts = {
  filterOptions: {
    ALL: 'All',
    [UserActivityType.PAYMENT_SUCCESSFUL]: 'Successful Payment',
    [UserActivityType.PAYMENT_FAILED]: 'Failed Payment',
    [UserActivityType.PAYMENT_REFUNDED]: 'Refunded Payment',
    [UserActivityType.WORKOUT_COMPLETED]: 'Workout Completed',
    [UserActivityType.CHECK_IN_SUBMITTED]: 'Check-in Submitted',
    [UserActivityType.HABIT_SUBMITTED]: 'Habit Submitted',
    [UserActivityType.POST_PAYMENT_FORM_SUBMIT]: 'Post Payment Form Submitted',
    [UserActivityType.SIGN_UP]: 'Sign Up',
    [UserActivityType.LEAD_STATUS_CHANGE]: 'Lead Status Change',
    [UserActivityType.LEAD_CALL_PLACED]: 'Lead Call Placed',
    [UserActivityType.LEAD_MSG]: 'Lead Message',
    [UserActivityType.SUBSCRIPTION_STATUS_CHANGE]: 'Subscription Status Change',
  },
  workoutComplete: 'Completed workout: “{workoutName}” assigned for {assignDate}',
  checkInSubmit: 'Submitted check-in for {weekText} {year}',
  failPayment: 'Payment of {currency}{amount} failed for the invoice “{invoice}”',
  paymentFailReason: '(Reason: {reason})',
  refundPayment: 'Refunded {currency}{amount} payment for the invoice “{invoice}”',
  successPayment: 'Payment of {currency}{amount} paid for the invoice “{invoice}”',
  habitCheckedOff: 'Completed habit(s): {habits} for the {weekText} {year}',
  send: 'Send',
  received: 'Received',
  message: '{msgType} a lead message: {message} ({time})',
  postPaymentForSubmit: 'Submitted post-payment form at {time}',
  signUp: 'Signed up at {time}',
  statusChange: '{type} status changed to {status}',
  callPlaced: 'Call placed by {userName}',
  lead: 'Lead',
  subscription: 'Subscription',
  filterLabel: 'Filter activity',
};

export default texts;
