import { DifficultyLevel } from '../../../../../../Model/Program';
import { fieldName } from './formFields';

const texts = {
  addNewProgramTitle: 'New Program',
  editProgramTitle: 'Edit Program',
  addNewProgram: 'Add New Program',
  saveChanges: 'Save Changes',
  cancel: 'Cancel',
  validation: {
    requiredField: 'Required',
  },
  field: {
    [fieldName.NAME]: 'Program Name',
    [fieldName.DIFFICULTY]: 'Difficulty',
    [fieldName.EQUIPMENT]: 'Equipment',
  },
  difficultyLevel: {
    [DifficultyLevel.BEGINNER]: 'Beginner',
    [DifficultyLevel.INTERMEDIATE]: 'Intermediate',
    [DifficultyLevel.ADVANCED]: 'Advanced',
  },
  programSaved: 'Program saved successfully',
  restAndRecovery: 'Rest & Recovery',
  updateProgramAlert: `Updating the {program} program won't affect assignments for already assigned programs. 
  Click the button below to view the clients and make any manual adjustments if needed.`,
  unaffectedUserList: 'List of clients unaffected',
  showUnaffectedUsers: 'See All Unaffected Clients',
};

export default texts;
