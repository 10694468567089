import format from 'string-template';

import { getFileExtension } from '../../../../../utils/file';
import { StorageProcessState } from '../../../../../hooks/useStorage';
import { storagePaths, pathPlaceholder } from '../../../../../utils/firebasePaths';

const uploadImage = async (
  imageUrl,
  upload,
  image,
  userId,
) => {
  const storagePath = format(storagePaths.USER_IMAGES, {
    [pathPlaceholder.USER_ID]: userId,
  });

  // Upload the image to firebase storage
  const { state, publicUrl } = await upload(
    imageUrl,
    getFileExtension(image.name),
    storagePath,
    'avatar',
    image.type,
    null,
    true,
  );

  if (state === StorageProcessState.FAILED) {
    throw new Error('There was an error in uploading the image to storage');
  }

  return publicUrl;
};

export {
  uploadImage,
};
