import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import format from 'string-template';

import MsgTemplate from '../../../../../Model/MsgTemplate';
import EmailTemplate from '../../../../../Model/EmailTemplate';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import {
  FileCategory,
  getFileCategory,
  getFileUrl,
} from '../../../../../utils/file';
import {
  DateFormat,
  timeStampToStr,
} from '../../../../../utils/date';
import RadioButtonGroup from '../../../RadioButtonGroup';
import InputLabel from '../../../../../components/v2/InputLabel';
import { isInsideSalesAdmin } from '../../../../../utils/postHog';
import MsgBody, { MsgPlaceholder } from './MsgBody';

import {
  StyledButton,
  StyledImage,
  StyledTextField,
  StyledVideo,
  TemplateContainer,
  Title,
  HistoryContainer,
  StyledTable,
  StyledLink,
  StyledLinkButton,
  StyledCell,
  TextContainer,
} from './styles';
import texts from './texts.json';

const ToggleState = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const TemplateView = ({
  template,
  templateHistory,
  templateConversionRates,
  loadConversionRates,
  msgBody,
  subject,
  onMsgBodyChange,
  onSubjectChange,
  onSaveTemplate,
  file,
  onFileChange,
  isMessageTemplateEnabled,
  onChangeMessageTemplateState,
  isFileRemoved,
  onRemoveFile,
  isEmailTemplateView,
}) => {
  const [isRatesLoaded, setIsRatesLoaded] = useState(false);

  const fileUrl = useMemo(() => {
    if (file) {
      onRemoveFile(false);
      return getFileUrl(file);
    }
    if (isFileRemoved) {
      return null;
    }
    return template?.attachmentUrl;
  }, [
    file,
    template,
    isFileRemoved,
    onRemoveFile,
  ]);
  const fileCategory = useMemo(() => (file ? getFileCategory(file) : template?.attachmentCategory), [file, template]);

  const hasEditPermission = isInsideSalesAdmin();

  const isComponentMountedRef = useComponentMounted();

  /**
   * Convert the template's rates to key value pairs of template versions and their conversion rates as percentages
   * Since the component is also used for viewing email templates,
   * we return an empty object to avoid errors in that view.
   */
  const conversionRates = useMemo(() => (
    isEmailTemplateView ? {} : Object.fromEntries(Object.entries(templateConversionRates)
      .map(([key, obj]) => [key, (obj.conversionRate * 100).toFixed(2)]))
  ), [
    templateConversionRates,
    isEmailTemplateView,
  ]);

  const onLoadClick = useCallback(async () => {
    await loadConversionRates();
    if (isComponentMountedRef.current) {
      setIsRatesLoaded(true);
    }
  }, [
    isComponentMountedRef,
    loadConversionRates,
  ]);

  const handleRemoveFile = () => {
    onFileChange(null);
    onRemoveFile(true);
  };

  return (
    <TemplateContainer spacing={2}>
      <Title>{template?.name}</Title>
      {!!fileUrl && fileCategory === FileCategory.IMAGE && (
        <StyledImage src={fileUrl} alt="media" />
      )}
      {!!fileUrl && fileCategory === FileCategory.VIDEO && (
        <StyledVideo controls key={fileUrl}>
          <source src={fileUrl} />
        </StyledVideo>
      )}
      {!isEmailTemplateView && (
        <StyledButton variant="contained" component="label" disabled={!hasEditPermission}>
          {!fileUrl ? texts.addMedia : texts.changeMedia}
          <input
            hidden
            type="file"
            onChange={(e) => (e.target.files.length ? onFileChange(e.target.files[0]) : null)}
          />
        </StyledButton>
      )}
      {!!fileUrl && (
        <StyledButton
          variant="contained"
          component="label"
          disabled={!hasEditPermission}
          onClick={handleRemoveFile}
        >
          {texts.removeMedia}
        </StyledButton>
      )}

      <RadioButtonGroup
        options={[
          {
            label: isEmailTemplateView ? texts.enableEmailTemplate : texts.enableMsgTemplate,
            value: ToggleState.ENABLED,
          }, {
            label: isEmailTemplateView ? texts.disableEmailTemplate : texts.disableMsgTemplate,
            value: ToggleState.DISABLED,
          },
        ]}
        selectedOption={isMessageTemplateEnabled ? ToggleState.ENABLED : ToggleState.DISABLED}
        onOptionChange={(value) => onChangeMessageTemplateState(value === ToggleState.ENABLED)}
        disabled={!hasEditPermission}
      />

      {isEmailTemplateView && (
        <TextContainer>
          <InputLabel>{texts.subject}</InputLabel>
          <StyledTextField
            variant="outlined"
            multiline
            rows={2}
            value={subject}
            onChange={(e) => onSubjectChange(e.target.value)}
            InputProps={{
              readOnly: !hasEditPermission,
            }}
          />
        </TextContainer>
      )}
      <MsgBody
        msgBody={msgBody}
        onMsgBodyChange={onMsgBodyChange}
        showLabel={isEmailTemplateView}
        isReadOnly={!hasEditPermission}
        placeHolders={!isEmailTemplateView ? [MsgPlaceholder.CLIENT_NAME, MsgPlaceholder.COACH_NAME]
          : [MsgPlaceholder.CLIENT_NAME, MsgPlaceholder.COACH_NAME, MsgPlaceholder.CONTACT_NUMBER]}
      />
      {hasEditPermission
        && <StyledButton variant="contained" onClick={onSaveTemplate}>{texts.saveTemplate}</StyledButton>}
      {templateHistory?.length > 0 && !isEmailTemplateView && (
        <HistoryContainer>
          <Title>{`${template?.name} - ${texts.history}`}</Title>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell variant="head">{texts.date}</TableCell>
                <TableCell variant="head">{texts.message}</TableCell>
                <TableCell variant="head">{texts.attachment}</TableCell>
                <TableCell variant="head">{texts.changedBy}</TableCell>
                <TableCell variant="head">
                  {isRatesLoaded
                    ? texts.conversion
                    : (
                      <StyledLinkButton onClick={onLoadClick}>
                        {texts.seeRates}
                      </StyledLinkButton>
                    )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templateHistory.map((msgTemplate) => (
                <TableRow key={msgTemplate.id}>
                  <TableCell>
                    {timeStampToStr(msgTemplate.createdAt, DateFormat.DATE_YEAR_MONTH_SHORT_DISPLAY_FORMAT)}
                  </TableCell>
                  <TableCell>{msgTemplate.msgBody}</TableCell>
                  <TableCell>
                    {msgTemplate.attachmentUrl ? (
                      <StyledLink href={msgTemplate.attachmentUrl}>{texts.link}</StyledLink>
                    ) : texts.emptyCell}
                  </TableCell>
                  <StyledCell>
                    {msgTemplate.createdByUserName ? msgTemplate.createdByUserName : texts.emptyCell}
                  </StyledCell>
                  <TableCell>
                    {conversionRates[msgTemplate.version]
                      ? format(texts.conversionRate, { rate: conversionRates[msgTemplate.version] })
                      : texts.emptyCell}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </HistoryContainer>
      )}
    </TemplateContainer>
  );
};

TemplateView.propTypes = {
  template: PropTypes.oneOfType([
    PropTypes.instanceOf(MsgTemplate),
    PropTypes.instanceOf(EmailTemplate),
  ]),
  templateHistory: PropTypes.arrayOf(PropTypes.instanceOf(MsgTemplate)),
  templateConversionRates: PropTypes.object,
  loadConversionRates: PropTypes.func,
  msgBody: PropTypes.string,
  subject: PropTypes.string,
  onMsgBodyChange: PropTypes.func.isRequired,
  onSubjectChange: PropTypes.func,
  onSaveTemplate: PropTypes.func.isRequired,
  file: PropTypes.instanceOf(File),
  onFileChange: PropTypes.func,
  isMessageTemplateEnabled: PropTypes.bool,
  onChangeMessageTemplateState: PropTypes.func.isRequired,
  isFileRemoved: PropTypes.bool,
  onRemoveFile: PropTypes.func,
  isEmailTemplateView: PropTypes.bool,
};

TemplateView.defaultProps = {
  isMessageTemplateEnabled: true,
  template: {},
  templateHistory: [],
  templateConversionRates: {},
  msgBody: '',
  subject: '',
  isEmailTemplateView: false,
  loadConversionRates: () => { },
  onFileChange: () => { },
  isFileRemoved: false,
  onRemoveFile: () => { },
  onSubjectChange: () => { },
};

TemplateView.defaultProps = {
  file: null,
};

export default TemplateView;
