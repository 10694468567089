import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import GenericDataGrid from '../../../../../../../components/GenericDataGrid';
import UserProfileCard from '../../../../../../../components/UserProfileCard';
import { DateFormat } from '../../../../../../../../utils/date';
import texts from './texts.json';

const UnaffectedUserList = ({ clientList }) => {
  const columns = useMemo(() => [
    {
      field: 'name',
      headerName: texts.field.clientName,
      flex: 1,
      renderCell: ({ row }) => (
        <UserProfileCard
          key={row.id}
          clientId={row.id}
          avatarSize="small"
          dataRow={{
            ...row.userDoc,
            userName: row.userDoc.name,
          }}
          showButtonsOnHover
        />
      ),
    },
    {
      field: 'startDate',
      headerName: texts.field.startDate,
      flex: 1,
      renderCell: ({ row }) => (
        moment(row.startDate.toDate()).format(DateFormat.DATE_FORMAT_COMMA)
      ),
    },
    {
      field: 'endDate',
      headerName: texts.field.endDate,
      flex: 1,
      renderCell: ({ row }) => (
        moment(row.endDate.toDate()).format(DateFormat.DATE_FORMAT_COMMA)
      ),
    },
  ], []);

  return (
    <GenericDataGrid
      rows={clientList}
      columns={columns}
      rowHeight={60}
      pagination
      autoPageSize
    />
  );
};

UnaffectedUserList.propTypes = {
  clientList: PropTypes.array.isRequired,
};

export default UnaffectedUserList;
