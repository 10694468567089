import styled from 'styled-components';

import colors from '../../../../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 450px;
  background-color: ${colors.base.alpha};
  overflow-y: auto;
`;

const FilterWrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  justify-content: flex-end;
`;

export {
  Container,
  FilterWrapper,
};
