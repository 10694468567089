/* eslint-disable max-len */
import CollectionName from './collections';

const pathPlaceholder = {
  COACH_ID: 'coachId',
  USER_ID: 'userId',
  MEAL_PLAN_TEMPLATE_ID: 'mealPlanTemplateId',
  PROGRAM_ID: 'programId',
  WORKOUT_ID: 'workoutId',
  COACH_CONFIG_ID: 'coachConfigId',
};

const firestorePaths = {
  COACH_ASSIGNMENT_COLLECTION: `/${CollectionName.COACH_ASSIGNMENT}`,
  COACH_COLLECTION: `/${CollectionName.COACH}`,
  INTEGRATIONS: `/${CollectionName.INTEGRATIONS}`,
  USER: `/${CollectionName.USER}`,
  TAG: `/${CollectionName.TAG}`,
  CONFIG: `/${CollectionName.CONFIG}`,
  CLIENT_SMS: `/${CollectionName.CLIENT_SMS}`,
  PUBLIC_DOCUMENT: '/app/public',
  WORKOUT: `/${CollectionName.WORKOUT}`,
  WORKOUT_ASSIGNMENT: `/${CollectionName.WORKOUT_ASSIGNMENT}`,
  PRODUCT: `/${CollectionName.PRODUCT}`,
  WEB_CUSTOMIZATION: '/webCustomization',
  MEAL_PLAN: `/${CollectionName.MEAL_PLAN}`,
  MEAL_PLAN_ASSIGNMENT: `/${CollectionName.MEAL_PLAN_ASSIGNMENT}`,
  LEADS: `/${CollectionName.LEADS}`,
  BROADCAST_MESSAGE: `/${CollectionName.BROADCAST_MESSAGE}`,
  BROADCAST_MESSAGE_REQUEST: `/${CollectionName.BROADCAST_MESSAGE_REQUEST}`,
  NUTRITION_PLAN: `/${CollectionName.NUTRITION_PLAN}`,
  NUTRITION_PLAN_REQUEST: `/${CollectionName.NUTRITION_PLAN_REQUEST}`,
  COACH_ONBOARDING: `/${CollectionName.COACH_ONBOARDING}`,
  COUPON: `/${CollectionName.COUPON}`,
  APP_CUSTOMIZATION: `/${CollectionName.APP_CUSTOMIZATION}`,
  INGREDIENT: `/${CollectionName.INGREDIENT}`,
  RECIPE: `/${CollectionName.RECIPE}`,
  MSG_TEMPLATES: `/${CollectionName.MSG_TEMPLATES}`,
  EMAIL_TEMPLATES: `/${CollectionName.EMAIL_TEMPLATES}`,
  COACH_REMINDER_NOTIFICATION: `/${CollectionName.COACH_REMINDER_NOTIFICATION}`,
  INTERNAL_ASSIGNMENT: `/${CollectionName.INTERNAL_ASSIGNMENT}`,
  METABASE_DASHBOARD: `/${CollectionName.METABASE_DASHBOARD}`,
  MSG_ENDPOINT: `/${CollectionName.MSG_ENDPOINT}`,
  PROGRAM: `/${CollectionName.PROGRAM}`,
  COACH_CONFIG: `/${CollectionName.COACH_CONFIG}`,
  APP_CUSTOM_THEME: `/${CollectionName.APP_CUSTOM_THEME}`,
  GAMEPLAY_SESSION: `/${CollectionName.GAMEPLAY_SESSION}`,
  CHAT_CHANNEL: `/${CollectionName.CHAT_CHANNEL}`,
};

firestorePaths
  .ONBOARDING_QUESTION_SEQUENCE_COLLECTION = `${firestorePaths.PUBLIC_DOCUMENT}/onboardingQuestionSequence`;
firestorePaths.ONBOARDING_QUESTION_COLLECTION = `${firestorePaths.PUBLIC_DOCUMENT}/onboardingQuestion`;

firestorePaths.COACH_DOC = `${firestorePaths.COACH_COLLECTION}/{${pathPlaceholder.COACH_ID}}`;
firestorePaths.USER_DOC = `${firestorePaths.USER}/{${pathPlaceholder.USER_ID}}`;
firestorePaths.COACH_CONFIG_DOC = `${firestorePaths.COACH_CONFIG}/{${pathPlaceholder.COACH_CONFIG_ID}}`;

// Stripe Integration
firestorePaths.STRIPE = `${firestorePaths.INTEGRATIONS}/stripe`;
firestorePaths.STRIPE_PRODUCTS = `${firestorePaths.STRIPE}/products`;
firestorePaths.STRIPE_SUBSCRIPTIONS = `${firestorePaths.STRIPE}/subscriptions`;

// Suggestic
firestorePaths.SUGGESTIC = `${firestorePaths.INTEGRATIONS}/suggestic`;
firestorePaths.SUGGESTIC_RECIPE = `${firestorePaths.SUGGESTIC}/recipe`;

// Msg templates
firestorePaths.DEFAULT_MSG_TEMPLATES = `${firestorePaths.MSG_TEMPLATES}/defaultMsgs`;
firestorePaths.COACH_MSG_TEMPLATES = `${firestorePaths.MSG_TEMPLATES}/{${pathPlaceholder.COACH_ID}}`;
firestorePaths.PROGRAM_DOC = `${firestorePaths.PROGRAM}/{${pathPlaceholder.PROGRAM_ID}}`;
firestorePaths.WORKOUT_DOC = `${firestorePaths.WORKOUT}/{${pathPlaceholder.WORKOUT_ID}}`;

firestorePaths.DEFAULT_EMAIL_TEMPLATES = `${firestorePaths.EMAIL_TEMPLATES}/defaultEmails`;
firestorePaths.COACH_EMAIL_TEMPLATES = `${firestorePaths.EMAIL_TEMPLATES}/{${pathPlaceholder.COACH_ID}}`;

export {
  firestorePaths,
  pathPlaceholder,
};
