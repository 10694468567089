import styled from 'styled-components';
import {
  Alert, Autocomplete, TextField, Typography,
} from '@mui/material';

import colors from '../../../styles/colors';
import Tabs from '../Tabs';

const Text = styled(Typography)`
  margin-bottom: 5px;
`;

const Title = styled(Text)`
  font-weight: bold;
  font-size: 24px;
`;

const StyledAutoComplete = styled(Autocomplete)`
  min-width: 250px;
  .MuiAutocomplete-inputRoot {
    height: 56px;
    overflow: hidden;
  }
`;

const StyledTextField = styled(TextField)`
  background: ${colors.base.alpha};
`;

const PermissionsErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const TabContainer = styled.div`
  background: ${colors.base.alpha};
`;

const StyledTabs = styled(Tabs)`
  flex: 1;
  border-color: ${colors.base.primary};
  margin-top: 8px;

  & .Mui-selected {
    background-color: ${colors.base.primary};
  }
`;

const Container = styled.div`
  padding: 0 0 20px 0;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const FilterRow = styled.div`
  display: flex;
  div:not(:last-child) {
    margin-right: 30px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
`;

const StyledAlert = styled(Alert)`
  margin: 20px;
`;

export {
  StyledAutoComplete,
  StyledTextField,
  PermissionsErrorContainer,
  StyledTabs,
  TabContainer,
  Container,
  FilterRow,
  TitleRow,
  Title,
  Description,
  StyledAlert,
};
