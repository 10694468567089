import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import {
  Backdrop, DialogContent, CircularProgress,
} from '@mui/material';

import FirebaseContext from '../../../../../context/FirebaseContext';
import useToast from '../../../../hooks/useToast';
import DialogModal from '../../../../../components/DialogModal';
import useComponentMounted from '../../../../../hooks/useComponentMounted';
import {
  StyledBox, IframeContainer, StyledPaper,
} from './styles';

import texts from './texts.json';

const CallModal = ({
  open, closeModal, leadId, leadPhoneNumber,
}) => {
  const { firebase: { remote } } = useContext(FirebaseContext);
  const { showToast } = useToast();
  const isComponentMountedRef = useComponentMounted();

  const [isLoading, setIsLoading] = useState(true);
  const [canLoadIframe, setCanLoadIframe] = useState(false);

  const createContact = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await remote('createContact', { leadId });
      const { success, error } = await response.json();

      if (success && isComponentMountedRef.current) {
        setIsLoading(false);
        setCanLoadIframe(true);
      } else {
        showToast(error || texts.somethingWentWrong, { error: true });
        closeModal();
      }
    } catch (error) {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }

      showToast(error, { error: true });
      closeModal();
    }
  }, [closeModal, isComponentMountedRef, leadId, remote, showToast]);

  useEffect(() => {
    createContact();
  }, [createContact]);

  return (
    <DialogModal
      open={open}
      onClose={closeModal}
      PaperComponent={StyledPaper}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <StyledBox>
          {canLoadIframe && (
            <IframeContainer>
              <iframe
                src={`https://dashboard.tryraz.com/conversations?id=${encodeURIComponent(leadPhoneNumber)}`}
                title="Tryraz Dashboard"
              />
            </IframeContainer>
          )}
        </StyledBox>
      </DialogContent>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </DialogModal>
  );
};

CallModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  leadId: PropTypes.string.isRequired,
  leadPhoneNumber: PropTypes.string.isRequired,
};

CallModal.defaultProps = {
};

export default compose(
  observer,
)(CallModal);
