import styled, { css } from 'styled-components';
import { Group, Email, Textsms } from '@mui/icons-material';

const iconStyles = css`
  height: 15px;
  width: 15px;
  margin-right: 8px;
`;

const LeadsIcon = styled(Group)`
  ${iconStyles};
`;

const EmailIcon = styled(Email)`
  ${iconStyles};
`;

const MessageTempIcon = styled(Textsms)`
  ${iconStyles}
`;

export {
  LeadsIcon,
  EmailIcon,
  MessageTempIcon,
};
