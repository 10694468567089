import MessageTemplates from '../MessageTemplates';
import texts from './texts.json';
import { EmailIcon, LeadsIcon, MessageTempIcon } from './styles';
import LeadConfigurations from './LeadConfigurations';
import EmailTemplates from './EmailTemplates';

const tabs = {
  LEADS: 'LEADS',
  EMAIL_TEMPLATES: 'EMAIL_TEMPLATES',
  MESSAGE_TEMPLATES: 'MESSAGE_TEMPLATES',
};

const tabsConfig = {
  [tabs.LEADS]: {
    label: texts.tabLabels[tabs.LEADS],
    icon: LeadsIcon,
    component: LeadConfigurations,
  },
  [tabs.EMAIL_TEMPLATES]: {
    label: texts.tabLabels[tabs.EMAIL_TEMPLATES],
    icon: EmailIcon,
    component: EmailTemplates,
  },
  [tabs.MESSAGE_TEMPLATES]: {
    label: texts.tabLabels[tabs.MESSAGE_TEMPLATES],
    icon: MessageTempIcon,
    component: MessageTemplates,
  },
};

export {
  tabs,
  tabsConfig,
};
