import styled from 'styled-components';
import {
  ImageSearchOutlined,
} from '@mui/icons-material';

import colors from '../../styles/colors';
import FileSelectButton from '../FileSelectButton';

const ImageContainer = styled.div`
  display: flex;
`;

const ImageUploader = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const ImageWrapper = styled.div`
  width: 300px;
  aspect-ratio: 4/3;
  display: flex;
  background-color: ${colors.shades.gamma1};
  overflow: hidden;
`;

const StyledPreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const StyledEditIcon = styled.div`
  background-color: ${colors.base.alpha};
  color: ${colors.base.beta};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items:  center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.25s;
`;

const StyledFileSelectButton = styled(FileSelectButton)`
  position: relative;
  width: 100%;

  &:hover > ${StyledEditIcon} {
    opacity: 1;
  }
`;

const NoPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  color: ${colors.base.beta};
`;

const StyledImageSearch = styled(ImageSearchOutlined)`
  color: ${colors.base.beta};
  width: 65px;
  height: auto;
`;

export {
  ImageContainer,
  ImageUploader,
  ImageWrapper,
  StyledPreview,
  StyledEditIcon,
  StyledFileSelectButton,
  NoPreviewContainer,
  StyledImageSearch,
};
